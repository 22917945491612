.product_container {
    z-index: 1;
    flex: 1 1;
    position: relative;
    z-index: 1;
}

.main_mod {
    border: 1px solid red;
    margin: 20px 0
}

.main_mod_options {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px;
}

.main_mod_option {
    padding: 5px;
    border: 1px solid green;
}