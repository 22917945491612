.modal_controller {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: center;
}
.warning_header svg {
    margin-bottom: 10px;
    margin-right: 5px;
}
.warning_header svg path {
    fill: var(--color-dark-raspberry);
}
.warning_header strong {
    color: var(--color-dark-raspberry);
}

.no_online_ordering_container {
    max-width: 430px;
    margin: 0 auto;
}