.container {
    padding-top: 60px;
    position: relative;
}

.container h3 {
    display: none;
}

.min_container {
    min-height: 400px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}