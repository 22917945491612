.cart_header_item {
    display: flex;
    gap: 8px;
}
.cart_header_item + .cart_header_item {
    margin-top: 4px;
}

.label {
    font-size: clamp(13px, var(--clamp-size-15));
    font-family: var(--font-brandon-text-bold);
    color: var(--color-text-blue);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 4px;
}

.value {
    font-size: clamp(13px, var(--clamp-size-16));
    font-family: var(--font-brandon-text-regular);
    color: var(--color-cocoa);
}

.value button {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    position: relative;
    top: -2px;
    margin-left: 5px;
}

.modal_controls {
    display: flex;
    gap: 5px;
    justify-content: center;

    width: 300px;
    margin: 0 auto;

}
.modal_controls button, .modal_controls .btn {
    width: auto;
    flex: 1;
    gap: 5px;
}
.modal_footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 639px) {
    .mobile_flex_wrap {
        flex-direction: column;
    }
}


/* //no cart no location  */
.sidebar_header_message {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
}
.sidebar_header_message_type {
    font-family: var(--font-brandon-text-bold);
    text-transform: capitalize;
}
.sidebar_header_message_store_name {
    max-width: 212px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
}