/* upsells  */

.upsells_container {
    background-color: var(--color-cream);
    padding: 20px;
    margin-top: 25px;
    border-radius: 5px;
}
.upsells_category_item {
    margin-top: 10px;
}
.upsells_title {
    font-size: 18px;
    color: var(--color-dark-raspberry);
    font-family: var(--font-bourton-hand-base);
    font-weight: normal;
    text-align: center;
}

.upsells_category_title {
    font-size: 16px;
    color: var(--color-dark-raspberry);
    font-family: var(--font-bourton-hand-base);
}

.upsells_link {
    border: 0;
    background: transparent;
    width: 100%;
    height: 100%;
    font-family: inherit;
    padding: 0;
    margin: 0;
}

.card_alt_item_inner_no_image {
    justify-content: center;
}

.card_alt_item_inner {
    justify-content: space-between;
}