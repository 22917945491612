.no_items_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.no_items_container_inner {
    text-align: center;
}
.title {
    font-size: clamp(32px, var(--clamp-size-40));
    margin-top: clamp(20px, var(--clamp-size-50));
    text-transform: uppercase;
    font-family: var(--font-bourton-hand-base);
    font-weight: normal;
}

.description {
    font-size: clamp(20px, var(--clamp-size-28));
    margin-top: clamp(16px, var(--clamp-size-30));
    color: var(--color-cocoa);
}
.no_items_container_footer {
    margin-top: clamp(20px, var(--clamp-size-50));
}