.footer {
    padding-top: 190px;
    background-image: url("../../assets/footer-bkg.svg");
    background-size: cover;
    position: relative;
    z-index: 2;
}
.msc_footer {
    background-image: url("../../assets/footer-bkg-msc.svg");
}

.directory_pages {
    background-image: url("../../assets/footer-bkg-directory.svg");
}
.footer_top {
    padding-bottom: 40px;
}

.footer_top ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.footer_main_menu ul {    
    gap: clamp(20px, 2.0833vw, 40px);    
}
.footer_main_menu a {
    color: var(--color-cream);
    font-size: 24px;
    text-transform: uppercase;
    font-family: var(--font-bourton-hand-base);
}
.copyright {
    text-align: center;
    margin-top: clamp(30px, 2.0833vw, 40px);
    color: #fff;
    font-size: 18px;
}

.footer_legal_menu ul {
    margin-top: 20px;
}

.footer_legal_menu a {
    font-size: 18px;
    color: #fff;
}
.footer_legal_menu li + li:before {
    content: "|";
    padding-left: 20px;
    padding-right: 20px;
    color: #fff;
}

.socials_menu {
    margin-top: 44px;
}
.socials_menu ul {
    gap: 24px;
}
.socials_menu a {
    position: relative;
}
.socials_menu .social_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
}

.footer_bottom {
    /* background-color: #8E0B57; */
    background-color: rgba(0,0,0,0.2);
}

.footer_bottom_inner {
    text-align: center;
    color: var(--color-cream);
    padding: 23px 0 30px;
    font-size: clamp(14px, 0.9375vw, 18px);
}

@media (max-width: 1023px) {
    .footer_main_menu ul {
        flex-direction: column;
        text-align: center;
        gap: 10px;
    }
}
@media (max-width: 768px) {
    .footer {
        min-height: 700px;
    }
}
@media (max-width: 560px) {
    .footer_legal_menu ul li:last-child {
        flex-basis: 100%;
        text-align: center;
        margin-top: 10px;
    }
    .footer_legal_menu ul li:last-child:before {
        display: none;
    }
}

.directory_pages .social_icon path {
    fill: var(--color-cocoa);
}