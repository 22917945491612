.container {
    padding: 50px clamp(20px, var(--clamp-size-50));
    background-color: #fff;
    border: 1px solid var(--color-grey-stroke);
    border-radius: 10px;
    filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.16));
    margin: 40px 0;
}


.mock_title {
    background: linear-gradient(-45deg,#333, #888,#fff);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    height: clamp(30px, var(--clamp-size-60));
    width: 280px;
    margin-bottom: 40px;
    max-width: 100%;
}

.mock_content {
    display: flex;
    gap: clamp(20px, var(--clamp-size-40))
}

.container_left {
    height: 300px;
    flex-basis: 57%;
    background-color: #fff;
    padding: 20px;
    margin-top: 14px;
    background: linear-gradient(-45deg,#333, #888,#fff);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
.container_right {
    height: 300px;
    flex-basis: 40%;
    background-color: #fff;
    padding: 20px;
    margin-top: 14px;
    background: linear-gradient(-45deg,#333, #888,#fff);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}