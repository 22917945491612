.banner {
    background-position: bottom center;
    background-repeat: no-repeat;
    background-image: url(../../assets/Wavy_Desktop_v2.svg);
    background-repeat: repeat-x;
    padding-bottom: 59px;
}
/* @media (max-width: 768px) {
    .banner {
        background-image: url(../../assets/Wawy_Mobile.png);
    }
} */
.banner_background {
    /* background-color: #c7ebf1; */
    background-color: #f7f6f4;
}

.banner_inner {
    /* padding-top: clamp(50px, 5.2083vw, 100px);
    padding-bottom: clamp(100px, 13.2812vw, 255px); */
    text-align: left;
    display: flex;
    /* height: 750px; */
    min-height: clamp(500px, 39.0625vw, 750px);
    align-items: center;
    min-height: 270px;
}
.banner_inner > div {
    flex: 1;
}

.banner_text {
    display: flex;
    align-items: center;
}
.banner_text_inner {
    text-align: center;
    /* max-width: 560px; */
    max-width: 680px;
    margin: 0 auto;
    
}

.banner_text_footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.subtitle {
    font-size: clamp(40px, var(--clamp-size-70));
    color: var(--color-dark-mint);
    font-family: var(--font-icing);
    line-height: 0.6;
}

.title {
    font-size: clamp(36px, var(--clamp-size-70));
    color: var(--color-dark-raspberry);
    text-transform: uppercase;
    line-height: 1.1;
    font-family: var(--font-bourton-hand-base);
    font-weight: normal;
}

.description {
    color: var(--color-cocoa);
    font-size: clamp(20px, var(--clamp-size-30));
}

.banner_image {
    height: 100%;
}

@media (max-width: 768px) {
    .banner {
        padding-bottom: 44px;
    }
    .banner_inner {
        flex-direction: column;
        padding: 40px 0 0;
        height: auto;
    }
    .banner_image {
        width: 80%;
    }
}
@media (max-width: 767px) {
    .banner_image {
        margin-top: 10px;
    }
    .description {
        display: none;
    }
}