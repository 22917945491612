.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    z-index: 200;
    transition: all .4s;
}

.backdrop_over {
    z-index: 210;
}