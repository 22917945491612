.container {
    margin-top: 12px;
}

.row {
    font-size: 16px;
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.today {
    font-family: var(--font-brandon-text-bold);
}