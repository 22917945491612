.product_details_container {
    display: flex;
    gap: clamp(30px, var(--clamp-size-60));
    padding-top: 30px;    
    align-items: flex-start;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    max-height: 100%;
    overflow: hidden;
    height: 70vh;
}
.background img {
    object-position: bottom;
}

@media (max-width: 1023px) {
    .product_details_container {
        flex-direction: column;
    }
}


.error {
    padding: 80px 0;
    min-height: 40vh;
}
.error h3 {
    margin-top: 40px;
}


/* presentation box  */
.presentation_box {
    filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.15));
    background-color: #fff;
    padding: clamp(20px, 2.0833vw, 30px) clamp(20px, 2.7778vw,40px);
    /* flex: 2; */
    flex-basis: 35%;
    position: sticky;
    top: 111px;
    /* background-image: url(../../../assets/presentation_box_bk.png); */
    background-size: cover;
    background-position: center;
}

.page_title {
    font-family: var(--font-bourton-hand-base);
    /* font-size: clamp(35px, var(--clamp-size-60)); */
    font-size: clamp(25px, var(--clamp-size-50));
    background: linear-gradient(-45deg,#333, #888,#fff);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    height: 57px;
}

.description {
    font-size: 16px;
    margin-top: 10px;
    color: var(--color-cocoa);
    line-height: 1.4;
    background: linear-gradient(-45deg,#333, #888,#fff);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    height: 70px;
}

.composite_image {
    border: 1px solid #E3E5E7;
    background-color: #fff;
    padding: 20px;
    margin-top: 14px;
    height: 300px;
    background: linear-gradient(-45deg,#333, #888,#fff);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
.composite_image_inner {
    text-align: center;
}

@media (max-width: 1023px) {
    .presentation_box {
        position: static;
        width: 100%;
    }
}

/* product options  */

.product_container {
    z-index: 1;
    flex: 1 1;
    position: relative;
    z-index: 1;
}
.options_container {
   
    margin-bottom: 10px;
    background-color: #fff;
    border: 1px solid #e3e5e7;
    padding: clamp(12px,var(--clamp-size-30)) clamp(20px,var(--clamp-size-40));
}
.main_mod {
    border: 1px solid red;
    margin: 20px 0
}

.main_mod_options {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px;
}

.main_mod_option {
    padding: 5px;
    border: 1px solid green;
}

.modifiers_container {
    display: flex;
    padding: 10px;
    gap: 10px;
    flex-wrap: wrap;
}

/* .modifier_item:first-child > div {
    margin: 0 !important;
} */
.options_item_header {
    padding: clamp(12px, var(--clamp-size-20)) clamp(20px, var(--clamp-size-40));
    background-color: #fff;
    border: 1px solid #E3E5E7;
    /* border-bottom: 0; */

    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;  
    scroll-margin-top: 180px;  

}
.options_item_header_arrow {
    transform: scale(-1);
    transition: all .3s;
    display: flex;
}
.trigger_open {
    border-bottom: 0;
}
.trigger_open .options_item_header_arrow{
    transform: scale(1);
}


.options_item_header_sub {
    margin-top: 10px;
}

.options_item_header_text {
    display: flex;
    align-items: center;
    gap: 10px;
}
.options_item_header h3 {
    color: var(--color-cocoa);
    font-size: clamp(20px, var(--clamp-size-30));
    font-size: clamp(18px,var(--clamp-size-24));
    font-family: var(--font-bourton-hand-base);
    text-transform: uppercase;
    height: 28px;
    width: 230px;
    background: linear-gradient(-45deg,#333, #888,#fff);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
.item_number {
    /* font-size: 30px; */
    font-family: var(--font-bourton-hand-base);
    color: var(--color-dark-raspberry);
}

.options_item_header_helper {
    font-size: clamp(16px, var(--clamp-size-20));
    font-family: var(--font-bourton-hand-base);
    font-weight: normal;
    color: var(--color-cocoa);
    display: inline;
    margin-left: 10px;
}

@media (max-width: 767px) {
    .options_item_header {
        border-radius: 10px 10px 0 0;
    }
    .form_controller {
        border-radius: 0 0 10px 10px;
    }
    .options_item_header_text svg {
        width: 20px;
        height: 20px;
    }

    .options_item_header_helper {
        margin-left: 0;
        display: block;
        /* display: none; */
    }
    .trigger_open .options_item_header_helper {
        display: block;
    }
}

.option_container {
    position: relative;
}
.option {
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.option:disabled {
    cursor: default;
}
.option:not(.active):disabled {
    opacity: .7;
}

.image_container {
    width: clamp(110px, var(--clamp-size-150));
    height: clamp(110px, var(--clamp-size-150));
    border: 1px solid var(--color-grey-stroke);
    /* border-radius: 100%; */
    /* background-color: var(--color-grey-bg); */
    background-color: #fff;
    position: relative;
    margin: 0 auto;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    background: linear-gradient(-45deg,#333, #888,#fff);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}
.image_container_inner {
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.image_container img {
    max-width: 85%;
}
.name {
    font-size: clamp(14px, var(--clamp-size-18));
    margin-top: 15px;
}

.option_description {
    font-size: 14px;
    font-family: var(--font-brandon-text-bold);
    color: #584446;
}
.option_title_text {
    background: linear-gradient(-45deg,#333, #888,#fff);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    height: 27px;
    width: 130px;
    margin: 0 auto;
    max-width: 100%;
}
.option_price {
    font-family: var(--font-brandon-text-bold);
    color: #584446;
    background: linear-gradient(-45deg,#333, #888,#fff);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    height: 27px;
    margin-top: 4px;
    width: 90px;
    margin: 4px auto 0;
    max-width: 100%;
}

.increment_decrement_container {
    display: flex;
    width: 100%;
    margin-top: 12px;
    justify-content: center;
    align-items: center;
    gap: 16px;
}
.increment_decrement_container button {
    border: 2px solid var(--color-dark-raspberry);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
}

.increment_decrement_container button svg {
    width: 70%;
}
.increment_decrement_container button path {
    fill: var(--color-dark-raspberry)
}
.item_count {
    font-size: 22px;
    color: var(--color-cocoa);
}

.check_mark {
    display: none;
    position: absolute;
    top: clamp(2px, 0.4167vw, 8px);
    right: clamp(2px, 0.4167vw, 8px);
    background-color: var(--color-dark-raspberry);
    width: 24px;
    height: 24px;
    border-radius: 100%;
    
    align-items: center;
    justify-content: center;
}
.check_mark svg {
    width: 60%;
}
.check_mark path {
    fill: #fff;
}

.active, button.active:disabled  {
    font-family: var(--font-brandon-text-bold);
    color: var(--color-text-blue);
}


.active .image_container {
    box-shadow: 0px 0px 0px 3px var(--color-dark-raspberry);
}


.active .check_mark {
    display: flex;
}

.option_title, .option_title button {
    font-size: 18px;
    line-height: 1.5;
    margin-top: 15px;
    text-align: center;
    width: 100%;
}
.option_price {
    color: var(--color-cocoa);
    font-family: var(--font-brandon-text-bold);
}

/* ///////// choice with no image */

.option_simple {
    flex: 1;
    width: 100%;
}
.option_simple {
    border-radius: 5px;
    border: 1px solid var(--color-grey-stroke);
    background: var(--color-grey-bg);
    display: block;
    padding: clamp(5px, var(--clamp-size-10));
    cursor: pointer;
    position: relative;
    height: 100%;
    cursor: pointer;
    flex-direction: row;
}
.option_simple .check_mark {
    display: none;
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: var(--color-dark-raspberry);
    width: 24px;
    height: 24px;
    border-radius: 100%;
    
    align-items: center;
    justify-content: center;
}
.option_simple .check_mark svg {
    width: 60%;
}
.option_simple .check_mark path {
    fill: #fff;
}
.option_simple.active {
    /* border: 3px solid var(--color-dark-raspberry); */
    box-shadow: 0px 0px 0px 3px var(--color-dark-raspberry);
    cursor: default;
}
.option_simple.active .check_mark {
    display: flex;
}
.option_simple .image_container {
    display: none;
}
.option_simple .option_title {
    background-color: #fff;
    padding: 12px clamp(12px, var(--clamp-size-20));
    /* font-size: clamp(14px,var(--clamp-size-16)); */
    font-size: 18px;
    font-family: var(--font-bourton-hand-base);
    height: 100%;
    line-height: 1.1;
    margin: 0;
    text-align: left;
}

.option_simple .option_price {
    color: var(--color-cocoa);
    font-size: 16px;
    font-family: var(--font-brandon-text-bold);
    display: block;
    margin-top: 10px;
}

.option_aggregate {
    position: relative;
}

.option_aggregate .option {
    width: 100%;
}


/* tooltip  */
.option_title_controller button {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    display: inline;
}
.tooltip_container {
    /* position: relative;
    display: inline; */
    position: absolute;
    display: inline;
    top: 0;
}
.tooltip_button {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
}
.tooltip_modal {
    border: 1px solid var(--color-dark-raspberry);
    /* padding: 8px 4px; */
    background-color: #fff;
    border-radius: 4px;
    text-align: center;
    font-size: 13px;
    color: var(--color-cocoa);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 190px;
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.16));
    margin-bottom: 7px;
}
.tooltip_modal_inner {
    padding: 8px 4px;
    background-color: #fff;
    border-radius: 4px;
    z-index: 2;
    position: relative;
}

.tail_icon {
    position: absolute;
    top: 99%;
    left: 50%;
    transform: translate(-50%, 0);
    height: 9px;
    line-height: 0;
    z-index: 1;
    /* position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -5.6px); */
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}