.product_options_footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    /* position: relative; */
    position: sticky;
    bottom: 20px;
    pointer-events: none;
}
.add_to_cart_message {
    position: absolute;
    background-color: #fff;
    padding: 5px;
    filter: drop-shadow(2px 4px 6px var(--color-cocoa));
    top: 75%;
    color: var(--color-cocoa);
    border-radius: 4px;
}
.product_options_footer_inner {
    background-color: #fff;
    filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.15));
    border-radius: 5px;
    padding: 10px;
    display: flex;
    gap: 10px;
    pointer-events: all;
}

.product_options_footer_controls {
    display: flex;    
}
.control_button {
    height: 100%;
    width: 45px;
    border: 1px solid var(--color-grey-stroke);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.control_button span {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: 2px solid var(--color-cocoa);
    display: flex;
    align-items: center;
    justify-content: center;
}
.control_button path {
    fill: var(--color-cocoa);
} 
.control_button:not(:disabled):hover {
    background-color: var(--color-cocoa);
}
.control_button:not(:disabled):hover span {
    border-color: #fff;
}
.control_button:not(:disabled):hover path {
    fill: #fff;
}
.control_button:disabled {
    opacity: .6;
    cursor: default;
}
.control_button_decrement {
    border-radius: 5px 0 0 5px;
}
.control_button_increment {
    border-radius: 0 5px 5px 0;
}
.controls_display {
    width: clamp(40px, 3.9062vw, 75px);
    background-color: var(--color-grey-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--color-grey-stroke);
    border-bottom: 1px solid var(--color-grey-stroke);
}

@media (max-width: 1023px) {
    .product_options_footer {
        position: sticky;
        bottom: 0;
        width: 100%;
    }
    .product_options_footer_inner {
        width: 100%;
        justify-content: space-between;
    }
}