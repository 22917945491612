
.sub_cat_sub_cat {
    display: flex;
    gap: 40px;
    border-bottom: 1px solid var(--color-grey-stroke);
    padding-bottom: 10px;
    margin-bottom: 30px;
    overflow-y: auto;
}
.sub_cat_sub_cat::-webkit-scrollbar {
    display: none;
}
/* .sub_cat_sub_cat::-webkit-scrollbar {
    width: 2px;
    height: 4px;
}
.sub_cat_sub_cat::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.sub_cat_sub_cat::-webkit-scrollbar-thumb {
    background-color: var(--color-cream);
    outline: 1px solid var(--color-sugar);
} */

.sub_cat_sub_cat_item {
    border: 0;
    margin: 0;
    background: transparent;
    padding: 0;
    font-size: clamp(16, var(--clamp-size-24));
    color: var(--color-text-blue);
    font-family: inherit;
    position: relative;
    /* min-width: 140px; */
    white-space: nowrap;
}
.sub_cat_sub_cat_item.cat_active {
    color: var(--color-dark-raspberry);
}
.sub_cat_sub_cat_item.cat_active:after {
    content: "";
    position: absolute;
    bottom: -9px;
    height: 2px;
    width: 100%;
    background-color: var(--color-dark-raspberry);
    left: 0;
}

.pagination {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
}
.pagination_view {
    font-size: 24px;
    color: var(--color-cocoa);
    font-family: var(--font-bourton-hand-base);
}
.pagination_controls {
    display: flex;
    gap: 10px;
    align-items: center;
}
.pagination_controls button {
    width: 40px;
    height: 40px;    

}
.control_next, .control_prev {
    border-radius: 5px;
    border: 0;
    background-color: var(--color-dark-raspberry);
}
.pagination_controls button:disabled {
    opacity: .6;
    cursor: default;
}
.pagination_pages {
    display: flex;
    align-items: center;
}
.pagination_pages button {
    border: 1px solid var(--color-grey-stroke);
    background-color: #fff;
    font-size: 14px;
    font-family: var(--font-bourton-hand-base);
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination_pages button:first-child {
    border-radius: 5px 0 0 5px;
}
.pagination_pages button:last-child {
    border-radius: 0 5px 5px 0;
}
.pagination_pages button.page_active {
    color: var(--color-dark-raspberry);
}

@media (max-width: 1023px) {
    .pagination {
        flex-direction: column;
    }
}


.navigation_container {
    position: relative;
}
.controls {
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0%;
    display: flex;
    justify-content: space-between;
}
.controls button {
    border: 0;
    background: transparent;
    padding: 5px;
    pointer-events: all;
    position: relative;
}
.button_left {
    left: -30px;
}

.button_right {
    right: -30px;
}

@media (max-width: 768px) {
    .button_left {
        left: -20px;
    }    
    .button_right {
        right: -20px;
    }
}