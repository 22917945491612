.info_module {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    pointer-events: none;
    
}

.info_module_inner {
    background-color: #fff;
    border-radius: 5px;
    filter: drop-shadow(10px 10px 4px rgba(0,0,0,0.3));
    padding: 50px 20px;
    position: relative;
    width: 700px;
    max-width: 100%;
    min-height: 400px;
    pointer-events: all;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.modal_module_inner {
    transition: all .3s;
    opacity: 0;
    transform: translateY(40px);
}
.modal_in_view {
    opacity: 1;
    transform: translateY(0);
}

.info_module_inner h3 {
    margin-bottom: 30px;
}

.close {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 10px;
    border-radius: 100%;
    transition: all .3s;
}
.close:hover {
    background-color: var(--color-dark-raspberry);
}

.close svg {
    width: 100%;
    height: 100%;
}
.close svg path {
    /* fill: #8d0e57; */
    transition: all .3s;
}
.close:hover svg path {
    fill: #fff;
}

.controls {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}