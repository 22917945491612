.sidebar_header {
    background-color: #fff;
    padding: 20px 50px 10px 20px;
    display: flex;
    gap: 1px;


    min-height: 78px;
}

.sidebar_header button {
    flex: 1;    
}
.sidebar_header button:first-child {
    border-radius: 5px 0 0 5px;
}
.sidebar_header button:last-child {
    border-radius: 0 5px 5px 0;
}

.sidebar_content {
    padding: 10px;
    flex: 1;
}
.sidebar_content_inner {
    border-radius: 21px;
    background: #FFF;
    padding: 24px 15px;
    height: 100%;


    /* display: flex;
    flex-direction: column;
    justify-content: center; */
}

.sidebar_content_inner .form_title + p {
    margin-top: 20px;
}

.form_title {
    display: flex;
    align-items: center;
    gap: 20px;
}
.form_title svg path {
    fill: var(--color-dark-raspberry)
}


.intro {
    margin-bottom: 30px;
}

.intro p + p {
    margin-top: 16px;
}

.fine_print {
    font-size: 70%;
    margin-top: 40px;
}

.terms_link {
    text-decoration: underline;
}


.migrate_disclaimer {
    border: 2px solid var(--color-dark-raspberry);
    color: var(--color-dark-raspberry);
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 30px;
    background-color: var(--color-light-bg);
    font-size: 12px;
}
.migrate_disclaimer button {
    text-decoration: underline;
    appearance: none;
    border: 0;
    margin: 0;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}
.migrate_disclaimer p + p {
    margin-top: 16px;
}

