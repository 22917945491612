.location {
    border-radius: 5px;
    border: 2px solid var(--color-grey-stroke);
    background: #FFF;
    /* padding: 15px; */
    /* padding: clamp(15px, var(--clamp-size-20)) clamp(15px, var(--clamp-size-30)); */
    width: 100%;
    text-align: left;
    color: var(--color-text-blue);
    overflow: hidden;
    margin-top: 10px;
    scroll-margin-top: 100px;
}
.location_inner {
    padding: clamp(15px, var(--clamp-size-20)) clamp(15px, var(--clamp-size-30)) 10px;
}
/* .location:hover,  */
.location_active {
    border-color: var(--color-dark-raspberry);
}


.location_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.location_header_controls {
    display: flex;
    align-items: center;
    gap: 5px;
}


.status {
    display: flex;
    gap: 6px;
    align-items: center;
}

.status_text {
    font-size: 10px;
    color: var(--color-dark-raspberry);
    padding: 8px 10px 4px ;
    border-radius: 200px;
    border: 1px solid var(--color-grey-stroke);
    background: #FBFAF8;
    text-transform: uppercase;
    font-family: var(--font-bourton-hand-base);
    line-height: 0.8;
}

.title {
    font-size: 16px;
    font-family: var(--font-brandon-text-bold);
    margin-top: 10px;

    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-start;
}

.distance {
    font-size: 14px;
    color: var(--color-cocoa);
    margin-top: 5px;
}

.program {
    padding: 12px 10px;
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
    
    margin-top: 15px;
}
.program_header, .program_header_inner {
    display: flex;
    gap: 15px;
    align-items: center;
}
.program_header {
    justify-content: space-between;
}

.phone {
    padding: 12px 10px;
    border-top: 1px solid var(--color-border);
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 15px;
}
.phone + .program {
    margin-top: 0;
}

.program_text {
    font-size: 14px;
}

.location_footer {
    display: flex;
    gap: 12px;
    margin-top: 12px;
    justify-content: flex-end;
    align-items: center;
}
.location_footer button {
    text-transform: uppercase;
    font-size: 12px;
    font-family: var(--font-brandon-text-bold);
}

.cobranded_container {
    display: flex;
    /* margin-top: 10px; */
    gap: 10px;
    background-color: var(--color-light-bg-v2);
    padding: 12px clamp(15px, var(--clamp-size-40));
}

.cobranded_image {
    flex-basis: 34px;
}
.cobranded_text {
    flex: 1;
    font-size: 12px;
}
.cobranded_text span {
    display: block;
    font-family: var(--font-brandon-text-bold);
    font-size: 14px;
}
.cobranded_text a {
    color: var(--color-dark-mint);
    font-family: var(--font-brandon-text-bold);
}

.loc_details_trigger {
    border: 0;
    background: transparent;
    display: flex;
    padding: 5px;
    transition: all .3s;
    transform: scale(-1);
}

.location_active .loc_details_trigger {
    transform: scale(1);
}


.modal_controller {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: center;
}
.warning_header svg {
    margin-bottom: 10px;
    margin-right: 5px;
}
.warning_header svg path {
    fill: var(--color-dark-raspberry);
}
.warning_header strong {
    color: var(--color-dark-raspberry);
}

.loc_hours_trigger {
    border: 0;
    background: transparent;
    display: flex;
    padding: 5px;
    transition: all .3s;
    transform: scale(-1);
}

.loc_hours_trigger.opened {
    transform: scale(1);
}

.logo {
    max-width: 200px;
    max-height: 50px;
}