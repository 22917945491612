.checkbox_container {
    /* margin-top: 30px; */

    margin-top: 10px;
    padding: clamp(12px, var(--clamp-size-20)) clamp(20px, var(--clamp-size-40));
    background-color: #fff;
    border: 1px solid #E3E5E7;
}
.checkbox_container .label {
    font-size: 16px ;
    width: calc(100% - 36px);
}


/* // the new "improved" design  */
@media (max-width: 639px) {
    .checkbox_container {
        padding: 0;
        border: 0;
    }
}