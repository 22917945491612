.alternative_container {
    margin-top: 10px;
}
.alternative_container_inner_wrapper {
    padding-top: 10px;
}
/* .btn_icon {
    width: 20px;
    height: 20px;
} */
.alternative_container_header {
    display: flex;
    gap: 5px;
}
.alternative_container_header > * {
    flex: 1;
}
/* .alternative_item {
    width: 100%;
    border: 0;
    padding: 5px;
    font-family: var(--font-brandon-text-bold);
    background-color: var(--color-grey-bg);
    border-radius: 5px;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
} */

.drawer {
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-grey-stroke);
}

/* .btn_hand.active {
    background-color: ;
} */


/* rewards  */
/* old style  */
/* .reward_item {
    display: flex;
    gap: 10px;
    padding: 10px 0;
}
.reward_item img {
    max-width: 100px;
}
.reward_item button, .loader_container {
    border: 0;
    padding: 5px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reward_item button svg path {
    fill: var(--color-dark-raspberry)
}
.reward_item_label {
    display: flex;
    align-items: center;
    font-family: var(--font-brandon-text-bold);
} */
/* .rewards_items {
    display: flex;
    overflow-x: auto;
    gap: 5px;
} */
.reward_item {
    padding: 7px;
    background-image: url(../../assets/rewards_texture.png);

    height: 100%;
}
.rewar_item_applied {
    width: 250px;
    margin: 0 auto;
}
.reward_item_inner {
    padding: 7px;
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.reward_item_head {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.reward_item_image {
    /* height: 90px; */
    height: 150px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
}
.reward_item_label {
    font-family: var(--font-bourton-hand-base);
    color: var(--color-cocoa);
    padding: 10px 0;
    text-align: center;

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.applied_coupons {
    display: flex;
    padding: 10px 0;
    font-family: var(--font-brandon-text-bold);
    align-items: center;
    gap: 10px;
}

.applied_coupons button, .applied_coupons .btn_placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: 0;
    background-color: transparent;
    transition: all .3s;
}

.applied_coupons button svg path {
    fill: var(--color-dark-raspberry);
    transition: all .3s;
}

.applied_coupons button:hover {
    background-color: var(--color-dark-raspberry);
}
.applied_coupons button:hover svg path {
    fill: #fff;
}
.apply_promo_container {
    position: relative;
}
.apply_promo_container input {
    padding-right: 125px;
}
.apply_promo_button {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    text-transform: uppercase;
    color: var(--color-dark-raspberry) !important;
    font-family: var(--font-brandon-text-bold);
    background: transparent !important;
}

.apply_promo_button:disabled {
    opacity: .5;
}

.rewards_container {
    background-color: var(--color-cream);
    border-radius: 10px;
    padding: 20px 10px;
}
.rewards_header {
    text-align: center;
}
.rewards_header_title {
    font-family: var(--font-bourton-hand-base);
    color: var(--color-dark-raspberry);
    font-weight: normal;
}