.product_options {
    /* flex: 3; */
    flex: 1;
    z-index: 1;
    position: relative;
}

.options_item {
    background-color: #fff;
    border: 1px solid #E3E5E7;
}
.options_item + .options_item, .options_item_customize {
    margin-top: 10px;
}

.options_item_header {
    padding: clamp(12px, var(--clamp-size-20)) clamp(20px, var(--clamp-size-40));
}
.options_item_header h3 {
    color: var(--color-cocoa);
    font-size: clamp(20px, var(--clamp-size-30));
    font-family: var(--font-bourton-hand-base);
    text-transform: uppercase;
    font-weight: normal;
}

.options_item_content {
    padding: clamp(12px, var(--clamp-size-30)) clamp(20px, var(--clamp-size-40));
    border-top: 1px solid #E3E5E7;
}


.form_controller {
    padding: clamp(12px, var(--clamp-size-30)) clamp(20px, var(--clamp-size-40));
    background-color: #fff;
    border-top: 1px solid #E3E5E7;
}
.instructions {
    color: var(--color-cocoa);
    font-size: 12px;
    font-style: italic;
    margin-top: 10px;
}

.form_group input[type="checkbox"] {
    position: static;
    appearance: initial;
    width: 25px;
    height: 25px;
    border: 1px solid var(--color-grey-stroke);
    border-radius: 4px;
    cursor: pointer;
}
.form_group input[type="checkbox"]:checked {
    background-color: var(--color-dark-raspberry);
}
.form_group + .form_group {
    margin-top: 10px;
}
.form_group_checkbox {
    display: flex;
    gap: 11px;
    align-items: center;
}
.form_group_checkbox label {
    font-size: 18px;
    color: var(--color-text-blue);
    text-transform: uppercase;
    font-family: var(--font-brandon-text-bold);
    cursor: pointer;
}
.form_group_checkbox label span {
    color: var(--color-dark-raspberry);
}

.label_title {
    text-transform: uppercase;
    font-size: 18px;
    font-family: var(--font-brandon-text-bold);
    margin-bottom: 10px;
    display: block;
}

.label_title span {
    color: var(--color-dark-raspberry);
}