.container {
    height: 100%;
    width: calc(100% - 480px);
    position: fixed;
    z-index: 201;
    top: 0;
    left: 0;
    background-color: var(--color-light-bg);
}

.container > div {
    margin: 0;
    height: 100%;
}