.presentation_box {
    filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.15));
    background-color: #fff;
    padding: clamp(20px, 2.0833vw, 30px) clamp(20px, 2.7778vw,40px);
    /* flex: 2; */
    flex-basis: 35%;
    position: sticky;
    top: 111px;
    /* background-image: url(../../../assets/presentation_box_bk.png); */
    background-size: cover;
    background-position: center;
}

.page_title {
    font-family: var(--font-bourton-hand-base);
    /* font-size: clamp(35px, var(--clamp-size-60)); */
    font-size: clamp(25px, var(--clamp-size-50));
}

.description {
    font-size: 16px;
    margin-top: 10px;
    color: var(--color-cocoa);
    line-height: 1.4;
}

.composite_image {
    border: 1px solid #E3E5E7;
    background-color: #fff;
    padding: 20px;
    margin-top: 14px;
}
.composite_image_inner {
    text-align: center;
}

@media (max-width: 1023px) {
    .presentation_box {
        position: static;
        width: 100%;
    }
}