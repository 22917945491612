html {
    --max-width: 1100px;
    --border-radius: 12px;
    --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
        "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
        "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

    /* colors  primary */
    /* --color-cocoa: #584446; */
    --color-cocoa: #584646;
    --color-raspberry: #08616a;
    --color-mint: #5bc6cc;    
    --color-text-blue: #242c37;

    /* colors  secondary */
    --color-dark-raspberry: #0b818e;
    --color-dark-raspberry-secondary: #0b818e;
    --color-red: #AA0061;
    --color-dark-mint: #0B818E;
    --color-lemon: #f8de8d;
    --color-cream: #FFEAD4;
    --color-light-mint: #9bd7d7;
    --color-strawberry: #ebc6de;
    --color-sugar: #dcc5b7;
    --color-cream: #FCF0DF;
    /* --color-pink: #ECC5DE; */
    --color-pink: #ECC5DE;

    /* colors  aditional */
    --color-grey-bg: #f9f9f9;
    --color-grey-stroke: #d6d8d9;
    --color-grey-stroke-dark: #B2B5B9;
    --color-light-bg: #f8e0be;
    --color-light-bg-v2: #FBFAF8;
    --color-border: #E3E5E7;

    /* fonts  */
    --font-brandon-text-regular: "Brandon Text";
    --font-brandon-text-bold: "Brandon Text Bold";
    --font-bourton-hand-base: "Bourton Hand Base";
    --font-bourton-hand-outline: "Bourton Hand Outline";
    --font-icing: "IcingScript";
    --font-intro-script: "Intro Script Bold";

    /* clamp  */
    /* 1920 px screen size  */
    --clamp-size-10: 0.5208vw, 10px;
    --clamp-size-14: 0.7291vw, 14px;
    --clamp-size-15: 0.7812vw, 15px;
    --clamp-size-16: 0.8333vw, 16px;
    --clamp-size-18: 0.9375vw, 18px;
    --clamp-size-20: 1.0417vw, 20px;
    --clamp-size-22: 1.1458vw, 22px;
    --clamp-size-24: 1.25vw, 24px;
    --clamp-size-25: 1.302vw, 25px;
    --clamp-size-26: 1.3541vw, 26px;
    --clamp-size-28: 1.4583vw, 28px;
    --clamp-size-30: 1.5625vw, 30px;
    --clamp-size-32: 1.6667vw, 32px;
    --clamp-size-35: 1.8229vw, 35px;
    --clamp-size-40: 2.0833vw, 40px;
    --clamp-size-45: 2.3437vw, 45px;
    --clamp-size-50: 2.6041vw, 50px;
    --clamp-size-55: 2.8645vw, 55px;
    --clamp-size-60: 3.125vw, 60px;
    --clamp-size-70: 3.6458vw, 70px;
    --clamp-size-80: 4.1667vw, 80px;
    --clamp-size-90: 4.6875vw, 90px;
    --clamp-size-100: 5.2083vw, 100px;
    --clamp-size-125: 6.5104vw, 125px;
    --clamp-size-150: 7.8125vw, 150px;
    --clamp-size-200: 10.4166vw, 200px;
    --clamp-size-300: 15.625vw, 300px;
}

@font-face {
    font-family: "Brandon Text";
    src: url("./fonts/BrandonText-Regular.woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Brandon Text Bold";
    src: url("./fonts/BrandonText-Bold.woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Bourton Hand Base";
    src: url("./fonts/BourtonHandBase.woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Bourton Hand Outline";
    src: url("./fonts/BourtonHandOutlineBold.woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'IcingScript';
    src: url('./fonts/IcingScript-medium.woff2') format('woff2'), url('./fonts/IcingScript-medium.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Intro Script Bold";
    src: url("./fonts/Intro-Script-Bold.otf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body {
    max-width: 100vw;
    height: 100%;
    /* overflow-x: hidden; */
}
html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
}
@-ms-viewport {
    width: device-width;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

/* main {
    overflow: hidden;
} */
.overflow_container {
    overflow: hidden;
}

html {
    font-size: 18px;
}
fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}
body {
    color: var(--color-text-blue);
    margin: 0;
    font-size: 1rem;
    background-image: url("./assets/Repeating_Marble.webp");
    font-family: var(--font-brandon-text-regular);
}
[tabindex="-1"]:focus {
    outline: 0 !important;
}
.small {
    font-size: 70%;
}
a {
    color: inherit;
    text-decoration: none;
}

div#root {
    height: 100%;
}
div#root > .App {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: space-between;
}

@media (prefers-color-scheme: dark) {
    html {
        color-scheme: dark;
    }
}

h1,
h2,
h3 {
    color: var(--color-dark-raspberry);
}

h1 {
    font-size: clamp(45px, var(--clamp-size-80));
    font-weight: normal;
}

h2, .h2 {
    font-size: clamp(35px, var(--clamp-size-60));
}

h3 {
    font-size: clamp(30px, var(--clamp-size-40));
}

h4 {
    font-size: 24px;
    color: var(--color-text-blue);
    font-family: var(--font-brandon-text-bold);
}

b,
strong {
    font-weight: bold;
}
small {
    font-size: 67%;
}
sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
img {
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
}
svg {
    overflow: hidden;
    vertical-align: middle;
}
table {
    border-collapse: collapse;
}

.text-center {
    text-align: center;
}

.relative {
    position: relative;
}

.image-contain {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.image-contain-auto {
    object-fit: contain;
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
        "Courier New", monospace;
    font-size: 1em;
}
pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.text-center {
    text-align: center;
}

.image-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.container-full {
    width: 1920px;
    max-width: 100%;
    padding-left: clamp(20px, var(--clamp-size-80));
    padding-right: clamp(20px, var(--clamp-size-80));
    margin: 0 auto;
}

@media (max-width: 768px) {
    .container-full {
        padding-left: 20px;
        padding-right: 20px;
    }
}

button * {
    pointer-events: none;
}
button {
    font-family: var(--clamp-brandon-text-regular);
    cursor: pointer;
    color: inherit;
}
.btn {
    display: flex;
    /* display: inline-flex; */
    align-items: center;
    justify-content: center;
    border: 0;
    background-color: var(--color-dark-raspberry);
    color: #fff;
    padding: 11px clamp(15px, 2.0312vw, 39px);
    /* font-size: clamp(12px, var(--clamp-size-16)); */
    font-size: clamp(14px, var(--clamp-size-16));
    line-height: 1.39;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s;
}
@media (min-width: 1025px) {
    button.btn:not(:disabled):hover, a.btn:not(:disabled):hover {
        background-color: var(--color-raspberry);
        color: #fff;
    }
}

div.btn, span.btn {
    cursor: default;
}
.btn-transparent {
    border: 2px solid var(--color-dark-raspberry);
    background: transparent;
    color: var(--color-dark-raspberry) ;
    font-family: var(--font-brandon-text-bold);
    width: 100%;
}
@media (min-width: 1025px) {
    button.btn-transparent:not(:disabled):hover {
        background-color: var(--color-dark-raspberry) !important;
        color: #fff;
    }
}

span.btn {
    display: inline-flex;
}
.btn-small {
    font-size: 14px;
    padding: 5px 10px 1px;
    font-family: var(--font-bourton-hand-base);
}
.btn-fit {
    width: 100%;
    height: 100%;
    padding: 11px;
    font-size: 16px;
}

.btn svg path {
    fill: #fff;
    /* stroke: #fff; */
}
.btn-large {
    font-size: clamp(16px, var(--clamp-size-22));
    gap: clamp(10px, var(--clamp-size-24));
    font-family: var(--font-brandon-text-bold);
}
.btn-border {
    background: transparent;
    /* border: 1px solid var(--color-text-blue); */
    border: 1px solid #d6d8d9;
    color: var(--color-text-blue);
}
.btn-border svg path {
    fill: var(--color-text-blue);
}
@media (min-width: 1025px) {
    button.btn-border:not(:disabled):hover {
        border-color: var(--color-dark-raspberry);
        color: var(--color-dark-raspberry);
        background-color: transparent;
    }
}


.btn-clear {
    border: 0;
    background: transparent;
}

.btn-underline {
    border: 0;
    background: transparent;
    text-decoration: underline;
}

.btn-flex-5 {
    display: flex;
    align-items: center;
    gap: 5px;
}

@media (min-width: 1025px) {
    .btn-underline:hover {
        color: var(--color-dark-raspberry);
    }
}
.btn-icon {
    margin: 0;
    padding: 5px;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
}

.btn-underline-red {
    font-size: 15px;
    position: absolute;
    top: -3px;
    right: 0;
    color: var(--color-dark-raspberry);
    font-family: var(--font-brandon-text-bold);
    display: flex;
    align-items: center;
    gap: 5px;
}
.btn-underline-static {
    position: static;
}
.btn-100 {
    width: 100%;
}
.btn:disabled {
    background: rgba(36, 44, 55, 0.3);
    cursor: default;
}

.btn-transparent:disabled {
    opacity: .5;
    cursor: not-allowed;
    background: transparent;
}
.btn-simple {
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    color: var(--color-dark-raspberry);
    text-decoration: underline;
    font-size: 14px;
}
.relative {
    position: relative;
}

.btn-border-input {
    font-family: var(--font-bourton-hand-base);
    font-size: clamp(14px, var(--clamp-size-18));
    border: 1px solid var(--color-dark-raspberry);
    padding: 13px 13px 10px;
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: transparent;
    color: var(--color-dark-raspberry);
    flex: 1;
}
.btn-border-input.btn-active {
    background-color: var(--color-dark-raspberry);
    color: #fff;
}

.btn-radiate {
    position: relative;
}
.btn-radiate:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: var(--color-dark-raspberry);
    animation: radiate 2s infinite;
}
@keyframes radiate {
    to {
        transform: scale(1.2);
        opacity: 0;
    }
}

.btn-fancy {
    display: inline-block;
    padding: 13px 15px;
    border-radius: 6px;
    background-color: #fff;
    color: var(--color-dark-raspberry);
    font-weight: 900;
    font-size: clamp(14px, 1.04166667vw, 20px);
    font-family: 'Brandon Text';
    transition: all 0.3s;
    max-width: 100%;
    text-align: center;
    line-height: clamp(15px, 1.5625vw, 30px);
    border: 2px solid var(--color-dark-raspberry);
    outline: none;
    text-decoration: none !important;
    text-transform: uppercase;
    cursor: pointer;
    min-width: clamp(180px, 14.58333333vw, 280px);
    -moz-box-shadow: 8px 5px 0px 0px var(--color-dark-raspberry);
    -webkit-box-shadow: 8px 5px 0px 0px var(--color-dark-raspberry);
    box-shadow: 8px 5px 0px 0px var(--color-dark-raspberry);
}
.btn-fancy:hover {
    background-color: var(--color-dark-raspberry);
    color: #fff;
    box-shadow: 0px 0px 0px 0px var(--color-dark-raspberry);
}


/* Form global  */

.form-fields-container .label, .label-appearance {
    font-family: var(--font-brandon-text-bold);
    /* text-transform: uppercase; */
    margin-bottom: 5px;
    display: block;
    font-size: clamp(14px, var(--clamp-size-18));
    text-align: left;
}
.form-fields-container .label-caps {
    text-transform: uppercase;
    margin-bottom: 12px;
}
.form-controller {
    position: relative;
}
.form-controller.invalid {
    opacity: .5;
    cursor: default;
    pointer-events: none;
}
.form-controller + .form-controller,
.form-controller + .form-controller-group,
.form-controller-group + .form-controller {
    margin-top: 20px;
}
.form-controller-group {
    display: flex;
    gap: 10px;
}
.form-controller-group.align-bottom {
    align-items: flex-end;
}

.form-controller-group + .form-controller-group {
    margin-top: 20px;
}
.form-controller-group .form-controller {
    margin: 0;
    flex: 1;
}
.form-controller-group .form-controller-3 {
    flex: 3;
}
.form-controller-group .form-controller-2 {
    flex: 2;
}
.form-controller-group .form-controller-1 {
    flex: 1;
}

.form-fields-container input[type="text"],
.form-fields-container input[type="email"],
.form-fields-container input[type="password"],
.form-fields-container input[type="date"],
.form-fields-container input[type="time"],
.form-fields-container input[type="number"],
.form-fields-container textarea, 
.select {
    border-radius: 5px;
    border: 1px solid var(--color-border);
    background: #fff;
    padding: 15px clamp(15px, var(--clamp-size-30));
    font-size: 18px;
    font-family: inherit;
    /* color: #8a8a8a; */
    color: var(--color-text-blue);
    flex: 1;
    height: 55px;
    width: 100%;
}
.form-fields-container input:disabled, 
.form-fields-container textarea:disabled {
    opacity: .5;
    cursor: not-allowed;
}
.form-fields-container input::placeholder,
.form-fields-container textarea::placeholder {
    color: #8a8a8a;
}
.form-fields-container textarea {
    height: 130px;
}
.form-fields-container input[type="radio"],
.form-fields-container input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    position: absolute;
}

.form-group-checkbox input[type="checkbox"] {
    position: static;
    appearance: initial;
    width: 25px;
    height: 25px;
    border: 1px solid var(--color-grey-stroke);
    border-radius: 4px;
    cursor: pointer;
}
.form-group-checkbox input[type="checkbox"]:checked {
    background-color: var(--color-dark-raspberry);
    background-image: url("./assets/check-mark.svg");
    background-repeat: no-repeat;
    background-position: center;
}
.form-group-checkbox {
    display: flex;
    gap: 11px;
    align-items: center;
}
.form-group-checkbox label {
    font-size: clamp(16px, var(--clamp-size-18));
    color: var(--color-text-blue);
    text-transform: uppercase;
    font-family: var(--font-brandon-text-bold);
    cursor: pointer;
}
.form-group-checkbox label span {
    color: var(--color-dark-raspberry);
}
.form-group-checkbox label.label-regular-font {
    font-family: var(--font-brandon-text-regular);
    text-transform: unset;
}
input.error {
    border-color: red !important;
}
.error-message {
    font-size: 13px;
    margin-top: 5px;
    padding-left: 5px;
    color: red;
}
.error-box {
    font-size: 13px;
    margin-top: 5px;
    padding: 5px;
    color: red;
    border: 1px solid red;
    margin-bottom: 5px;
    background-color: var(--color-grey-bg);
}

.grid-3 {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    gap: clamp(15px, 2.0833vw, 40px);
}
.gap_24 {
    gap: clamp(12px, var(--clamp-size-24));
}
@media (max-width: 1023px) {
    .grid-3 {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 767px) {
    .grid-3 {
        grid-template-columns: repeat(1, 1fr);
    }
}

.grid-5 {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(5, 1fr);
    /* grid-template-columns: repeat(auto-fill, 150px); */
    grid-gap: clamp(10px, 2.0833vw, 40px);
}
@media (max-width: 1400px) {
    .grid-5 {
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (max-width: 767px) {
    .grid-5 {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 580px) {
    .grid-5 {
        grid-template-columns: repeat(2, 1fr);
    }
}
.grid-4 {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(4, 1fr);
    /* grid-template-columns: repeat(auto-fill, 150px); */
    grid-gap: clamp(10px, 2.0833vw, 40px);
}
@media (max-width: 1400px) {
    .grid-4 {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 767px) {
    .grid-4 {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 580px) {
    .grid-4 {
        grid-template-columns: repeat(2, 1fr);
    }
}


.categories_header_fixed {
    position: fixed;
    top: 80px;
    z-index: 50;
    /* padding: 30px 0; */
    /* padding: 10px 0; */
    background-color: #ecc5de;
    border-bottom: 5px solid var(--color-dark-raspberry);
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    transition: all 0.35s;
}

.header-hide + main .categories_header_fixed {
    top: 0;
    transition: all 0.35s;
}
.categories_header_fixed .sticky-image {
    display: none;
}
.header-hide + main .presentation-box-create {
    top: 0;
    transition: all 0.35s;
}

.categories_controller_container_fixed {
    position: fixed;
    /* top: 179px; */
    /* top: clamp(150px, 8.6458vw,166px); */
    top: 150px;
    left: 0;
    width: 100%;
    border-radius: 0;
    z-index: 49;

    transition: all 0.35s;
}
.header-hide + main .categories_controller_container_fixed {
    top: 72px;
    transition: all 0.35s;
}
.categories_controller_container_fixed .categories_controller {
    /* width: 100%; */
    border-radius: 0;
    border: 0;
}
@media (max-width: 1023px) {
    .categories_header_fixed {
        top: 60px;
    }
    .categories_controller_container_fixed {
        top: 130px;
    }
}
@media (max-width: 767px) {
    .categories_header_fixed {
        top: 92px;
    }
    .categories_controller_container_fixed {
        top: 163px;
    }
}
/* .fixed-bar-appear {
    transform: translateY(-100%);
}
.fixed-bar-enter {
    transform: translateY(0);
} */

.fixed-bar-enter {
    opacity: 0;
    /* transform: scale(0.9); */
    transform: translateY(-100%);
}
.fixed-bar-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}
.fixed-bar-exit {
    opacity: 1;
}
.fixed-bar-exit-active {
    opacity: 0;
    /* transform: scale(0.9); */
    transform: translateY(-100%);
    transition: opacity 300ms, transform 300ms;
}


.sidebar-group-enter {
    opacity: 1;
    transform: translateX(100%);
    transition: transform 400ms;
}
.sidebar-group-enter-active {
    transform: translateX(0) !important;
    transition: transform 400ms;
}

.sidebar-group-exit, .sidebar-group-enter-done {
    transform: translateX(0) !important;
    opacity: 1;
}
.sidebar-group-exit-active {
    transform: translateX(150%) !important;
    transition: transform 400ms;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.btn-loader {
    background-color: var(--color-dark-raspberry);
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
}

.loader-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader {
    width: 25px;
    height: 25px;
    border: 5px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
.loader.dark {
    border: 5px solid var(--color-dark-raspberry);
    border-bottom-color: transparent;
}


.loader.small {
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-bottom-color: transparent;
}
.loader.dark-raspberry {
    width: 10px;
    height: 10px;
    border: 2px solid var(--color-dark-raspberry);
    border-bottom-color: transparent;
}

.loader.medium {
    width: 25px;
    height: 25px;
}

.loader.locations {
    width: 14px;
    height: 14px;
    border: 2px solid var(--color-text-blue);
    border-bottom-color: transparent;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.preparing_restaurant {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1111;
}
.preparing_restaurant_backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* backdrop-filter: blur(12px); */
    backdrop-filter: blur(6px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.preparing_restaurant_container {
    max-width: 70%;
    text-align: center;
}
.preparing_restaurant_loader {
    width: 60px;
    height: 60px;
    border: 10px solid var(--color-dark-raspberry);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
.preparing_restaurant_message {
    font-size: 60px;
    font-family: var(--font-bourton-hand-base);
    margin-top: 40px;
}


.test-p {
    background-color: red;
    color: #fff;
    font-weight: 700;
}


.cart_footer_button {
    background-color: #fff;
    padding: 20px 30px;

    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    filter: drop-shadow(2px 4px 6px black);
}
.cart_footer_button button {
    width: 100%;
}


/* olo style  */
.olo-pay {
    border-radius: 5px;
    border: 1px solid var(--color-border);
    background: #fff;
    /* padding: 15px clamp(15px, var(--clamp-size-30)); */
    padding: 15px;
    font-size: 18px;
    font-family: inherit;
    color: #8a8a8a;
    flex: 1;
    height: 55px;
    width: 100%;
}

.sidebar-icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.sidebar-icon svg {
    width: 100%;
    height: 100%;
}
.sidebar-icon svg path {
    fill: var(--color-dark-raspberry);
}
.sidebar-title {
    margin-bottom: 30px;
}


/* .card_alt_items {
    display: flex;
    overflow-x: auto;
    gap: 5px;
} */
.card_alt_item {
    padding: 7px;
    background-image: url(./assets/rewards_texture.png);
    height: 100%;
}
.card_alt_applied {
    width: 250px;
    margin: 0 auto;
}
.card_alt_item_inner {
    padding: 7px;
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.card_alt_item_image {
    height: 90px;
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
}
.card_alt_label {
    font-family: var(--font-bourton-hand-base);
    color: var(--color-cocoa);
    padding: 10px 0;
    text-align: center;
}
.card_alt_title {
    font-family: var(--font-bourton-hand-base);
    color: var(--color-dark-raspberry);
    color: var(--color-cocoa);
    font-size: 15px;
    padding: 10px 0;
    text-align: center;
}
.card_button {
    border: 2px solid var(--color-dark-raspberry);
    background: transparent;
    color: var(--color-dark-raspberry);
    font-family: var(--font-brandon-text-bold);
    width: 100%;
    padding: 10px;
    line-height: 1;
}
.card_alt_price {
    font-size: 12px;
    text-align: center;
}
.card_alt_btn-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.splide-custom-arrows .splide__arrow {
    width: 40px;
    height: 40px;
    /* border: 2px solid var(--color-cocoa); */
    background-color: #fff;
    opacity: 1;
}
.splide-custom-arrows .splide__arrow:disabled {
    cursor: default;
}
.splide-custom-arrows .splide__arrow svg {
    fill: inherit;
    width: 100%;
    height: 100%;
}
.splide-custom-arrows .splide__arrow--prev {
    left: -20px;
}
.splide-custom-arrows .splide__arrow--prev svg {
    transform: none;
}
.splide-custom-arrows .splide__arrow--next {
    right: -20px;
}


.rewards-enter {
    opacity: 0;
    height: 0;
    transform: translateY(-40px);
}
.rewards-enter-active {
    opacity: 1;
    height: 100%;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}
.rewards-exit {
    opacity: 0;
    height: 0;
}
.rewards-exit-active {
    opacity: 0;
    height: 0;
    transform: translateY(-40px);
    transition: opacity 300ms, transform 300ms;
}


.top-drop-enter {
    opacity: 0;
    transform: translateY(-40px);
}
.top-drop-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
}
.top-drop-exit {
    opacity: 1;
}
.top-drop-exit-active {
    opacity: 0;
    transform: translateY(-40px);
    transition: opacity 300ms, transform 300ms;
}


/* google autocmplete  */
.pac-icon {
    display: none;
}
.pac-container {
    border: 1px solid var(--color-border);
}
.pac-item {
    font-size: 16px;
    color: (var(--color-text-blue));
    font-family: var(--font-brandon-text-regular);
    padding: 15px 30px;
    text-align: center;
}
.pac-item-query {
    font-size: 16px;
}
.pac-item:hover, .pac-item-selected {
    background-color: #ECC5DE33;
    font-family: var(--font-brandon-text-bold);
}


.background-invisible {
    /* background-color: rebeccapurple; */
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    /* z-index: 101; */
    z-index: 2;
}



/* /////  */
.test_red {
    color: red;
    font-size: 12px;
    font-family: var(--font-brandon-text-bold);
}


.banner-slider .splide__pagination {
    position: static;
    margin: 0;
    padding: 0;
    gap: 20px;
}

.banner-slider .splide__pagination__page {
    background: transparent;
    width: clamp(20px, 1.5104vw, 29px);
    height: clamp(22px, 1.6145vw, 31px);
    display: flex;
    border: none;
    transition: all 0.3s;
    overflow: hidden;
    color: transparent;
    padding: 0;
    outline: none;
    cursor: pointer;
    text-align: center;
    position: relative;
    background-image: url(./assets/banner-pagination.svg);
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    opacity: 1;
}

.banner-slider .splide__pagination__page.is-active {
    background: transparent;
    background-image: url(./assets/banner-pagination-active.svg);
    transform: none;
    background-size: contain;
    background-repeat: no-repeat;
}



/* radar autocomplete  */
.radar-autocomplete-wrapper {
    position: absolute !important;
    top: 100%;
    left: 0;
    width: 100% !important;
}
.radar-autocomplete-results-list {
    position: absolute !important;
    z-index: 1000;
}

.radar-autocomplete-results-list {
    border-radius: 20px !important;
    overflow: hidden !important;
}
.radar-autocomplete-results-list .pinIcon {
    display: none !important;
}

.radar-autocomplete-results-item {
    border-top: 1px solid var(--color-grey-stroke);
    padding: 16px !important;
    font-size: 16px !important;
}
.radar-autocomplete-results-item:first-child {
    border: 0 !important;
}
.radar-powered {
    display: none !important;
}
.radar-autocomplete-results-marker {
    display: none;
}

/* RADAR POPUP */
.maplibregl-popup-content {
    width: 325px;
    padding: 15px !important;
    border-radius: 12px !important;
    border: 1px solid var(--color-grey-stroke) !important;
    box-shadow: unset !important;
}
 
.maplibregl-popup-close-button {
    width: 24px;
    height: 24px;
    border: 1px solid var(--color-dark-raspberry-secondary) !important;
    top: -12px !important;
    right: -12px !important;
    background-color: #fff !important;
    border-radius: 100% !important;
    color: var(--color-dark-raspberry-secondary);
    font-size: 1.5rem;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    line-height: 0;
    font-family: var(--font-brandon-text-regular);
}

.map-popup-header {
    font-family: var(--font-brandon-text-bold);
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 6px;
}

.map-popup-distance {
    color: var(--color-cocoa);
    font-family: var(--font-brandon-text-regular);
    font-size: 14px;
    margin-bottom: 4px;
}

.map-popup-row {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    font-family: var(--font-brandon-text-regular);
    line-height: 1.3;
    padding-block: 6px;
}

.map-popup-row img {
    max-height: 12px;
}

.map-popup-footer {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 8px;
    gap: 8px;
}

.map-popup-footer .btn {
    font-family: var(--font-bourton-hand-base);
    font-size: 12px;
    text-transform: uppercase;
    padding: 12px 24px;
}

.map-popup-footer button.get-directions-btn {
    color: var(--color-dark-raspberry-secondary);
    background: unset;
}

.map-popup-footer button.get-directions-btn:hover {
    color: var(--color-dark-raspberry-secondary);
    background: unset;
}

.marker-active {
    animation: MapPinBounce .5s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

.marker-active svg rect {
    fill: var(--color-dark-raspberry);
}
.marker-active svg rect + path {
    fill: var(--color-dark-raspberry);
}

@keyframes MapPinBounce {
    0%, 100% {
        top: 0;
    }
    50% {
        top: -10px;
    }
}