.create_options_container {
    background-color: #fff;
    padding: 5px 0;
}
.create_options {
    border: 1px solid var(--color-grey-stroke);
    border-radius: 5px;
    padding: 10px;
}
.create_options h3 {
    font-size: 14px;
    font-family: var(--font-brandon-text-regular);
    color: var(--color-text-blue);
    font-weight: normal;
    margin-bottom: 5px;
}

.disclaimer {
    margin-top: 10px;
    font-size: 11px;
    color: var(--color-cocoa);
}

.disclaimer p + p{
    margin-top: 10px;
}