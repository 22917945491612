.sidebar {
    width: 480px;
    max-width: 100%;
    background-color: var(--color-light-bg);
    position: fixed;
    z-index: 201;
    top: 0;
    right: 0;
    height: 100%;

    display: flex;
    flex-direction: column;
    transition: all .3s;
    transform: translateX(100%);
}
.sidebar.active {
    transform: translateX(0);
}

.close_button {
    position: absolute;
    top: 24px;
    right: 6px;
    padding: 10px;
    border-radius: 100%;
    width: 39px;
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}
.close_button svg path {
    transition: all .3s;
}

.close_button:hover {
    background-color: var(--color-dark-raspberry);
}
.close_button:hover svg path {
    fill: #fff;
}

.close_button.location {
    width: 32px;
    height: 32px;
    background-color: var(--color-dark-raspberry);
    top: 34px;
    right: 24px;
}
.close_button.location svg path {
    fill: #fff;
}

.sidebar_header {
    background-color: #fff;
    padding: 20px 30px 10px;
    min-height: 78px;
}
.sidebar_content {
    padding: 10px;
}
.sidebar_content_inner {
    border-radius: 21px;
    background: #FFF;
    padding: 24px 15px;
}

.form_input_container {
    display: flex;
    width: 100%;
    margin-top: 6px;
}
.form_input_container input {
    border-radius: 5px 0 0 5px;
    border: 1px solid #E3E5E7;
    background: #FFF;
    padding: 15px 30px;
    font-size: 18px;
    font-family: inherit;
    color: #8A8A8A;
    flex: 1;
    height: 55px;
    border-right: 0;
}
.form_input_container button {
    background-color: var(--color-dark-raspberry);
    border: 0;
    width: 55px;
    height: 55px;
    border-radius: 0 5px 5px 0;
}