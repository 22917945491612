.change_selections_date_confirm {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}
.date_time_container {
    margin-top: 24px;
}
.days_container {
    display: flex;
    gap: 5px;
    justify-content: center;
    padding: 20px 0;
    flex-wrap: wrap;
}

.day {
    border: 1px solid var(--color-grey-stroke);
    padding: 5px;
    width: 55px;
    background-color: transparent;
    font-size: 16px;
    transition: all .3s;
}
.day.active, .day:hover {
    border-color: var(--color-dark-raspberry);
    background-color: var(--color-dark-raspberry);
    color: #fff;
}

.week_day {
    font-family: var(--font-brandon-text-bold);
}

/* .select {
    width: 300px;
    max-width: 100%;
    flex: 1;
} */
.select {
    padding-right: 50px;   
    padding-left: 20px;
    appearance: none;
    /* min-width: 230px; */
    cursor: pointer;
}

@media (max-width: 767px) {
    .select {
        font-size: 15px;
    }
}
.select:disabled {
    cursor: default;
}
.selects_container {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    align-items: center;
    justify-content: center;
    /* margin: 20px auto 0; */
    flex-wrap: wrap;
}

.time_slots_container {
    position: relative;
    /* width: 300px; */
    max-width: 100%;
    flex: 1;
}

.loading_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.controls {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.open_drawer {
    position: absolute;
    /* top: 0;
    right: 0; */
    /* background: var(--color-dark-raspberry); */
    background: #fff;
    /* width: 50px;
    height: 55px; */
    top: 2px;
    right: 2px;
    width: 51px;
    height: 51px;
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.time_slots_container.disabled .open_drawer{
    opacity: .5;
}



/* ///////////////////////////////// */
.scheduled_inputs {
    margin-top: 20px;
    margin-bottom: 20px;
}

.radio_input_container {
    display: flex;
    align-items: center;
}
.radio_input_container > div {
    flex: 1;
}

.radio_input_item {
    line-height: 1;
}

.radio_input_item input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    position: absolute;
}
.radio_input_item label {
    font-family: var(--font-bourton-hand-base);
    font-size: clamp(14px, var(--clamp-size-18));
    border: 1px solid #D6D8D9;
    padding: 13px 13px 10px;
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.radio_input_item_small label {
    font-size: 14px;
    padding: 14px;
}
.radio_input_container > div:first-child label {
    border-radius: 5px 0 0 5px;
}
.radio_input_container > div:last-child label  {
    border-radius: 0 5px 5px 0;
}
.radio_input_item input[type="radio"]:checked + label {
    border-color: var(--color-dark-raspberry);
    color: var(--color-dark-raspberry);
    cursor: default;
}

/* input[type="date"], input[type="time"] {
    position: relative;
    color: #fff !important;
}
input[type="date"]:before{
    position: absolute;
    top: 0px; 
    left: 0px;
    width: 100%;
    height: 100%;
    content: attr(data-date);
    display: inline-block;
    padding: 15px 30px;
    font-size: 18px;
    color: #8a8a8a !important;
}
input[type="time"]:before {
    position: absolute;
    top: 0px; 
    left: 0px;
    width: 100%;
    height: 100%;
    content: attr(data-time);
    display: inline-block;
    padding: 15px 30px;
    font-size: 18px;
    color: #8a8a8a !important;
}
input[type="date"]::-webkit-datetime-edit, input[type="date"]::-webkit-inner-spin-button, input[type="date"]::-webkit-clear-button, 
input[type="time"]::-webkit-datetime-edit, input[type="time"]::-webkit-inner-spin-button, input[type="time"]::-webkit-clear-button {
    display: none;
}
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.relative {
    position: relative;
}

.open_drawer {
    position: absolute;
    background: #fff;
    top: 2px;
    right: 2px;
    width: 51px;
    height: 51px;
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
} */