.section {
    padding: clamp(40px, var(--clamp-size-80)) 0;
}
.section h1 {
    text-align: center;
    font-family: var(--font-bourton-hand-base);
}

.description {
    text-align: center;
    margin-top: 30px;
    font-size: clamp(16px, var(--clamp-size-28));
    margin-bottom: 60px;
}

.products_container {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 40px;
   
}
.categories_controller_container {
    text-align: center;
    margin-bottom: 40px;    
}

.categories_controller_container_fixed {
    border: 1px solid #e3e5e7;
    background-color: #fff;
}
.categories_controller_container.sticky_menu {
    position: sticky;
    top: 0;
    z-index: 100;
    
}
.categories_controller {
    display: inline-flex;
    background-color: #fff;
    /* padding: 30px; */
    padding: clamp(10px, var(--clamp-size-30)) clamp(20px, var(--clamp-size-60));
    border-radius: 200px;
    border: 1px solid var(--color-border);
    /* justify-content: center; */
    align-items: flex-start;
    /* gap: 60px; */
    gap: 20px;
    transition: all .35s;
    min-width: 400px;    
    max-width: 100%;

    overflow-x: auto;
}
.categories_controller::-webkit-scrollbar {
    display: none;
}
@media (max-width: 768px) {
    .categories_controller {
        min-width: unset;
        width: 100%;
        gap: 0;
    } 
}
.categories_controller_container.sticky_menu .categories_controller {
    padding: 14px;
    width: 100%;
}
/* .categories_controller_container.sticky_menu .categories_controller {
    position: fixed;
    top: 0;
} */
.items_container {
    margin-top: clamp(20px, var(--clamp-size-40));
}

.sub_categories_item {
    scroll-margin: 350px;
}
.sub_categories_item + .sub_categories_item {
    margin-top: clamp(30px, var(--clamp-size-80));
}

.icon {
    width: clamp(50px, var(--clamp-size-70));
    height: clamp(50px, var(--clamp-size-70));
    border: 1px solid #E3E5E7;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    transition: all .35s;
}
.icon svg, .icon img {
    width: 60%;
    max-height: 60%;
}

.sub_title {
    font-size: clamp(30px, var(--clamp-size-55));
    color: #584646;
    font-family: var(--font-bourton-hand-base);
    display: flex;
    gap: 20px;
    align-items: center;
    font-weight: normal;
}

.sub_description {
    font-size: 20px;
    margin-top: 20px;
    color: #584446;
}


.navigation_container {
    position: relative;
}
.controls {
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0%;
    display: flex;
    justify-content: space-between;
}
.controls button {
    border: 0;
    background: transparent;
    padding: 5px;
    pointer-events: all;
    position: relative;
}
.button_left {
    left: -30px;
}

.button_right {
    right: -30px;
}

@media (max-width: 768px) {
    .button_left {
        left: -20px;
    }    
    .button_right {
        right: -20px;
    }
}

.categories_controller_container_fixed  .button_left {
    left: 0;
}
.categories_controller_container_fixed  .button_right {
    right: 0;
}