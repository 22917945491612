.olo_card_form {
    margin-top: 20px;
}
.set_credit_card {
    padding: 11px;
    height: 55px;
    width: 100%;
    font-family: var(--font-bourton-hand-base);
    padding-bottom: 6px;
}

.selected_payment_container {
    margin-top: 20px;
}

.selected_payment_container h4 {
    font-family: var(--font-bourton-hand-base);
    font-weight: normal;
    color: var(--color-cocoa);
}

.selected_payment_item {
    border: 1px solid var(--color-grey-stroke);
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
}
.selected_payment_item svg path {
    fill: var(--color-dark-raspberry);
}
.selected_payment_item span {
    font-family: var(--font-brandon-text-bold);
    text-transform: uppercase;
}
.selected_payment_item button {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 100%;
    background: transparent;
    position: absolute;
    top: 8px;
    right: 20px;
}

.submit_container {
    background-color: #fff;
    padding: 20px 30px;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    filter: drop-shadow(2px 4px 6px black);
}

.input {
    border-radius: 5px;
    border: 1px solid var(--color-border);
    background: #fff;
    padding: 15px clamp(15px, var(--clamp-size-30));
    font-size: 18px;
    font-family: inherit;
    /* color: #8a8a8a; */
    color: var(--color-text-blue);
    flex: 1 1;
    height: 55px;
    width: 100%;
}

.form_controller_credit_card {
    flex-basis: 65% !important;
}
.form_controller_cvv {
    flex-basis: 35% !important;
}