.warning_container {
    position: fixed;
    z-index: 9999;
    /* bottom: 0;
    right: 0; */
    padding: 20px;

    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 100%;

    top: 5%;
    right: 50%;
    transform: translateX(50%);
}
.warning_container ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.warning_container ul li + li {
    margin-top: 5px;
}


.warning_inner {
    background-color: #fff;
    padding: clamp(20px, 2.6041vw, 50px);
    font-size: clamp(16px, var(--clamp-size-20));
    color: var(--color-dark-raspberry);
    font-family: var(--font-brandon-text-bold);
    border-radius: 10px;
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.3));
    border: 1px solid var(--color-dark-raspberry);
    width: 500px;
    max-width: 100%;

    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;
}

.warning.success .warning_inner {
    border: 1px solid var(--color-mint);
    color: var(--color-mint);
}

.warning_icon path, .warning_inner button path {
    fill: var(--color-dark-raspberry);
}

/* .warning.success .warning_icon path,
.warning.success .warning_inner button path {
    fill: var(--color-mint);
} */

.close_button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 0;
    background-color: transparent;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    padding: 5px;
    transition: all .3s;
}

.close_button svg {
    width: 100%;
    height: 100%;
}
.close_button svg path {
    transition: all .3s;
}

.close_button:hover {
    background-color: var(--color-dark-raspberry);
}
.close_button:hover svg path {
    fill: #fff;
}