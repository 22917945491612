.form_input_container {
    display: flex;
}
.form_input_container input {
    border-radius: 5px 0 0 5px !important;
    border-right: 0;
    border-right: 0 !important;
}
.form_input_container button {
    background-color: var(--color-dark-raspberry);
    border: 0;
    width: 55px;
    height: 55px;
    border-radius: 0 5px 5px 0;
}
.delivery_description_text {
    padding-top: 30px;
}
.no_delivery_text {
    /* max-width: 250px; */
    background-color: var(--color-pink);
    padding: 24px 20px;
    /* margin: 20px auto 0; */
}
.text_info {
    text-align: center;
    /* padding: 30px 30px 0; */
}
.text_info h4 {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 20px;
}

.text_info p {
    font-size: 16px;
    /* max-width: 240px; */
    margin: 0 auto;
    line-height: 1.4;
}
.no_delivery_text button {
    font-size: 18px;
    font-family: var(--font-brandon-text-bold);
    color: var(--color-dark-raspberry-secondary);
}
.text_info_header > svg {
    width: 97px;
    height: 60px;
    /* fill: var(--color-cocoa); */
}
.text_info_header > svg path {
    fill: var(--color-cocoa);
}
.cta_container {
    margin-top: 20px;
}

.text_info_item {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: left;
    margin-top: 20px;
}
.text_info_item svg {
    width: 24px;
    height: 31px;
}

.text_info_item_header {
    font-family: var(--font-brandon-text-bold);
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 5px;
} 

.form_fields_container {
    margin-top: 20px;
}


.delivery_success_confirmation {
    background-color: var(--color-cream);
    padding: 24px 20px;
}