.control_box {
    display: flex;
    align-items: center;
    border: 0;
    background: transparent;
    gap: 10px;
    color: var(--color-text-blue);
    font-size: 16px;
    line-height: 1;
}

.cart_items_number {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: var(--color-dark-raspberry-secondary);
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -6px;
    right: -9px;
}

.cart_total {
    font-family: var(--font-brandon-text-bold);
    font-size: 18px;
}