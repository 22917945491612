.product_details_container {
    display: flex;
    gap: clamp(30px, var(--clamp-size-60));
    padding-top: 30px;    
    align-items: flex-start;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    max-height: 100%;
    overflow: hidden;
    height: 70vh;
}
.background img {
    object-position: bottom;
}

@media (max-width: 1023px) {
    .product_details_container {
        flex-direction: column;
    }
}


.error {
    padding: 80px 0;
    min-height: 40vh;
}
.error h3 {
    margin-top: 40px;
}

@media (max-width: 767px) {
    /* .product_details_container {
        padding-top: 0;
    } */
    .container {
        padding: 0;
    }
}

.back_to_menu_container {
    position: relative;
    padding-top: clamp(25px, var(--clamp-size-40));
}

.back_to_menu_container button, .back_to_menu_container a {
    position: relative;
    background: transparent;
    display: flex;
    gap: 10px;
    align-items: center;
    border: 0;
    font-size: 20px;
    font-family: var(--font-brandon-text-bold);
    /* font-family: var(--font-brandon-text-regular); */
    text-decoration: underline;
    color: var(--color-dark-raspberry-secondary);
}
.back_to_menu_container button svg, .back_to_menu_container a svg {
    width: clamp(24px, var(--clamp-size-32));
    height: clamp(24px, var(--clamp-size-32));
}