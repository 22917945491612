.container {
    position: relative;
    background-image: url(../../../../assets/canvas-background.png);
    background-size: cover;
    display: block;
    line-height: 0;
    position: relative;
    padding-bottom: 100%;
}

.canvas {
    width: 100%;
}

.border_container,
.border_container_second {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.border_container_second {
    z-index: 2;
}

.dollops_image {
    position: absolute;
    top: 0;
    left: 0;
}

.dollops_image .red {
    filter: grayscale(1);
}

.loader_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.download_btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    border: 0;
    margin: 0;
    padding: 0;
    background: transparent;
}
.download_btn svg {
    transition: all 0.3s;
}
.download_btn svg path {
    fill: var(--color-dark-raspberry);
    transition: all 0.3s;
}
.download_btn:hover svg {
    filter: drop-shadow(1px 1px 6px var(--color-dark-raspberry));
}
.download_btn:hover svg path {
    fill: var(--color-red);
}
/* .dollops_image:nth-child(even) img {
    filter:grayscale(1);
    filter:hue-rotate(215deg);
} */

@media (max-width: 1023px) {
    .download_btn {
        bottom: 6px;
        right: 0px;
        width: 24px;
        height: 24px;
    }
}

/* .canvas_back {
    position: absolute;
    pointer-events: none;
    opacity: 0;
} */

.image_container_item {
    position: absolute;
}

.text_container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: var(--font-intro-script);
    /* font-family: var(--font-bourton-hand-base); */
    font-size: clamp(26px, var(--clamp-size-50));
    line-height: 1;
    color: #fff;
    text-shadow: rgb(253, 248, 225) 0px 0px 0px, rgb(253, 248, 225) 1px 0px 0px,
        rgb(253, 248, 225) 2px 0px 0px, rgb(253, 248, 225) 3px 1px 0px,
        rgba(0, 0, 0, 0.13) 4px -4px 3px, rgba(0, 0, 0, 0.5) 3px -1px 5px,
        rgba(0, 0, 0, 0.2) 5px 3px 3px;
    /* text-shadow: 0px 0px 0 rgb(243,181,0),
        1px -1px 0 rgb(243,181,0),
        2px -2px 0 rgb(243,181,0),
        3px -3px 0 rgb(243,181,0),
        4px -4px 3px rgba(0,0,0,0.13),
        4px -4px 1px rgba(0,0,0,0.5),
        0px 0px 3px rgba(0,0,0,.2); */
    line-height: 1.1;
}

.container_static {
    transition: all 0.3s;
}
.container_loading {
    filter: blur(4px);
}
