.options_item_header {
    padding: clamp(12px, var(--clamp-size-20)) clamp(20px, var(--clamp-size-40));
    background-color: #fff;
    border: 1px solid #E3E5E7;
    /* border-bottom: 0; */

    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;  
    scroll-margin-top: 180px;  

}
.options_item_header_arrow {
    transform: scale(-1);
    transition: all .3s;
    display: flex;
}
.trigger_open {
    border-bottom: 0;
}
.trigger_open .options_item_header_arrow{
    transform: scale(1);
}


.options_item_header_sub {
    margin-top: 10px;
}

.options_item_header_text {
    display: flex;
    align-items: center;
    gap: 10px;
}
.options_item_header h3 {
    color: var(--color-cocoa);
    font-size: clamp(20px, var(--clamp-size-30));
    font-size: clamp(18px,var(--clamp-size-24));
    font-family: var(--font-bourton-hand-base);
    text-transform: uppercase;
}
.item_number {
    /* font-size: 30px; */
    font-family: var(--font-bourton-hand-base);
    color: var(--color-dark-raspberry);
}

.options_item_header_helper {
    font-size: clamp(16px, var(--clamp-size-20));
    font-family: var(--font-bourton-hand-base);
    font-weight: normal;
    color: var(--color-cocoa);
    display: inline;
    margin-left: 10px;
}

@media (max-width: 767px) {
    .options_item_header {
        border-radius: 10px 10px 0 0;
    }
    .form_controller {
        border-radius: 0 0 10px 10px;
    }
    .options_item_header_text svg {
        width: 20px;
        height: 20px;
    }

    .options_item_header_helper {
        margin-left: 0;
        display: block;
        /* display: none; */
    }
    .trigger_open .options_item_header_helper {
        display: block;
    }
}