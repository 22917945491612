.presentation_box {
    filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.15));
    background-color: #fff;
    /* padding: 10px; */
    background-size: cover;
    background-position: center;
    position: sticky;
    /* top: 94px; */
    top: 0;
    /* overflow-y: auto; */
    /* max-height: calc(100vh - 140px); */
    width: 100%;
    z-index: 100;
}

/* .presentation_box_inner {
    position: fixed;
    top: 111px;
    max-height: calc(100vh - 140px);
} */

.presentation_box::-webkit-scrollbar {
    width: 5px;
}
.presentation_box::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.presentation_box::-webkit-scrollbar-thumb {
    background-color: var(--color-dark-raspberry);
    outline: 1px solid slategrey;
}

.page_title {
    font-family: var(--font-bourton-hand-base);
    /* font-size: clamp(35px, var(--clamp-size-60)); */
    font-size: clamp(25px, var(--clamp-size-50));
}

.description {
    font-size: 16px;
    margin-top: 10px;
    color: var(--color-cocoa);
    line-height: 1.4;
}

.composite_image {
    border: 1px solid #E3E5E7;
    /* max-width: 100%; */
    max-width: 75%;
    margin: 0 auto;
    /* background-color: #fff;
    padding: 20px;
    margin-top: 14px; */
}
.composite_image_inner {
    text-align: center;
}


.create_options {
    padding: 30px clamp(15px, var(--clamp-size-40));
    border: 1px solid var(--color-grey-stroke);
    background-color: #fff;
    margin-top: 20px;
}

.create_options h3 {
    color: var(--color-cocoa);
    font-size: clamp(20px, var(--clamp-size-30));
    font-family: var(--font-bourton-hand-base);
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 5px;
}

.simple_choices {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}

.text_choices {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media (max-width: 800px) {
    .simple_choices {
        grid-template-columns: repeat(1, 1fr);
    }
    .create_options {
        padding: 0;
        border: 0;
        background: transparent;
    }
    .create_options h3 {
        font-size: 16px;
    }
}
@media (max-width: 480px) {
    .simple_choices {
        grid-gap: 5px;
    }
}


/* // mobile template  */

.page_title_button {
    width: 100%;
    font-size: 20px;
    font-family: var(--font-bourton-hand-base);
}

.page_title_button button {
    font-size: inherit;
    color: var(--color-cocoa);
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}   

.page_title_icon {
    transform: scale(-1);
    transition: all .3s;
    display: flex;
}
.page_title_icon svg path {
    fill: var(--color-dark-raspberry);
}
.drawer_button_opened .page_title_icon{
    transform: scale(1);
}
.drawer_button_opened .page_title_icon svg path {
    fill: var(--color-cocoa);
}
.presentation_box_drawer {
    display: none;
}
.drawer_opened {
    display: block;
}

.drawer_button_opened_text {
    color: var(--color-dark-raspberry);
    display: flex;
    align-items: center;
    gap: 5px;
}

.disclaimer {
    color: var(--color-cocoa);
    font-size: 12px;
    font-style: italic;
    margin-top: 10px;
}