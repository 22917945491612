.options_container {
   
    /* margin-bottom: 10px; */
    background-color: #fff;
    border: 1px solid #e3e5e7;
    padding: clamp(12px,var(--clamp-size-30)) clamp(20px,var(--clamp-size-40));
    
}
.options_containe_parent {
    display: none;
}
.drwer_open {
    display: block;
}