.min_container {
    min-height: 400px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.text_container {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 30px;
    font-size: 20px;
}

.btn_container {
    display: flex;
    justify-content: center;
}

