.form {
    margin-top: 10px;
}

/* .form_input_container {
    display: flex;
} */
.form_input_container input {
    border-radius: 5px 0 0 5px !important;
    border-right: 0;
    border-right: 0 !important;
}
.form_input_container .btn {
    background-color: var(--color-dark-raspberry);
    border: 0;
    width: 55px;
    height: 55px;
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.form_input_container .btn:disabled {
    opacity: .7;
    cursor: not-allowed;
}

.text_info {
    text-align: center;
    padding: 20px 20px 0;
}
.text_info h4 {
    margin-top: 25px;
    margin-bottom: 10px;
}

.text_info p {
    font-size: 16px;
    max-width: 240px;
    margin: 0 auto;
    line-height: 1.4;
}


.text_info svg {
    width: 45px;
    height: auto;
}

.form_cart_is_delivery {
    margin-top: 10px;
}

.form_cart_is_delivery button + button {
    margin-top: 20px;
}