.wrapper {
    padding: 50px clamp(20px, var(--clamp-size-50));
    background-color: #fff;
    border: 1px solid var(--color-grey-stroke);
    border-radius: 10px;
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.16));
    margin: 40px 0;
}
.wrapper_inner {
    display: flex;
    gap: 40px;
}
.content {
    margin-top: 40px;
    flex-basis: 60%;
}
.sidebar {
    flex-basis: 40%;
    margin-top: 40px;
    position: relative;
}
.sidebar:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 80%;
    background: var(--color-grey-stroke);
    top: 0;
    left: -30px;
}

.content ul {
    list-style: none;
    margin: 30px 0;
    padding: 0;
}
.content ul li + li {
    margin-top: 10px;
}
.content ul li a {
    font-size: 20px;
    font-family: var(--font-bourton-hand-base);
}
.content ul li a:hover {
    color: var(--color-dark-raspberry);
}
.content ul li button, .content ul li .btn_subtitute {
    background: transparent;
    border: 0;
    font-size: 20px;
    color: var(--color-dark-raspberry);
    text-decoration: underline;
    font-family: var(--font-brandon-text-bold);
}
.content ul li button:hover {
    color: var(--color-raspberry);
}


.info_item {
    font-size: 20px;
    margin-top: 20px;
}
.info_item span {
    font-family: var(--font-brandon-text-bold);
}
.back_to_container {
    margin-bottom: 10px;
}
.back_to_container a {
    display: flex;
    gap: 5px;
    line-height: 1;
}
.form_wrapper {
    max-width: 700px;
    margin: 30px auto 0;
}

.success_message {
    padding: 50px;
    margin-bottom: 40px;
    background-color: #abffd1;
    border: 1px solid #27b969;
    border-radius: 10px;
}

.favorite_orders {
    margin-top: 40px;
}
.favorites + .favorites {
    margin-top: 60px;
}

.controls {
    display: flex;
    gap: 10px;
}

@media (max-width: 1023px) {
    .wrapper_inner {
        flex-direction: column;
    }
    .sidebar {
        margin-top: 0;
    }
    .sidebar:before {
        left: 0;
        top: -30px;
        width: 200px;
        height: 1px;
    }
}