.text_info {
    text-align: center;
    margin-top: 20px;
    /* padding: 30px 30px 0; */
}
.text_info h4 {
    margin-top: 15px;
    margin-bottom: 10px;
}

.text_info p {
    font-size: 18px;
    /* max-width: 240px; */
    margin: 0 auto;
    line-height: 1.4;
}
.text_info_header > svg {
    width: 97px;
    height: 40px;
    
}
.text_info_header > svg path {
    fill: var(--color-dark-raspberry);
}
.cta_container {
    margin-top: 20px;
}

.text_info_item {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: left;
    margin-top: 20px;
}
.text_info_item svg {
    width: 24px;
    height: 31px;
}

.text_info_item_header {
    font-family: var(--font-brandon-text-bold);
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 5px;
} 

.form_fields_container {
    margin-top: 20px;
}