.card {
    border-radius: 5px;
    background-color: var(--color-grey-bg);
    padding: 15px;
    margin-top: 10px;
    /* display: flex;
    gap: 20px; */
}

.card_header {
    font-size: clamp(16px, var(--clamp-size-18));
    font-family: var(--font-brandon-text-bold);
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.card_row {
    margin-top: 5px;
}

.card_row span {
    font-family: var(--font-brandon-text-bold);
}