.container {
    display: flex;
    /* padding: 40px 0; */
}

.map_container {
    flex: 3;
}
.map_container > div {
    position: static;
    width: 100%;
}

.search_container {
    width: 450px;
    max-width: 100%;
}
.search_container > div > div {
    padding-top: 20px;
}

@media (max-width: 900px) {
    .search_container {
        width: 100%;
    }
    .search_container > div > div {
        padding-top: 40px;
    }
    .search_container > div > div > div:last-child {
        height: auto;
    }
}