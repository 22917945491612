.item {
    background-color: var(--color-grey-bg);
    padding: 10px;
    border-radius: 5px;
    position: relative;
    display: flex;
    gap: 20px;
}

.item + .item {
    margin-top: 10px;
}

.remove_item {
    position: absolute;
    top: 15px;
    right: 20px;
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
}
.title {
    color: var(--color-cocoa);
    font-size: 16px;
    font-family: var(--font-brandon-text-bold);
    padding-right: 40px;
    text-transform: capitalize;
}

.description {
    font-size: 14px;
    margin-top: 10px;
}

.item_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.cart_item_operation {
    display: flex;
    border: 1px solid var(--color-grey-stroke); 
    border-radius: 5px;
    width: 85px;
    font-size: 14px;
    font-family: var(--font-brandon-text-bold);
    align-items: center;
}
.item_count {
    text-align: center;
}
.cart_item_operation > * {
    flex: 1;
}
.increment_decrement {
    border: 0;
    padding: 8px 4px;
    margin: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
}

.price {
    font-size: 15px;
    font-family: var(--font-brandon-text-bold);
}

.text {
    flex-basis: calc((100% - 120px));
}
.image_container {
    width: 100px;
    height: 100px;
    border: 1px solid var(--color-grey-stroke);
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
}

.custom_fields {
    margin-top: 5px;
    font-size: 14px;
}

.special_instructions {
    margin-top: 5px;
    font-size: 14px;
}
.special_instructions span {
    font-family: var(--font-brandon-text-bold);
}