.supercategories {
    padding: 60px 0 80px;    
}
.text_content {
    width: 100%;
}
.title {
    padding: 20px clamp(0px, 1.302vw, 25px) 20px;
    color: var(--color-cocoa);
    font-size: clamp(20px, var(--clamp-size-26));
    font-family: var(--font-brandon-text-bold);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s;
}
.title_icon {
    width: 32px;
    height: 32px;
    display: flex;
}

.image_container {
    height: clamp(100px, var(--clamp-size-300));
    background-color: var(--color-light-bg);
    border-radius: 5px 5px 0px 0px;
    overflow: hidden;
}

.button_link {
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
}

.button_link:hover .title {
    color: var(--color-dark-raspberry);
}
.title svg path {
    transition: all .3s;
}
.button_link:hover .title svg path {
    fill: var(--color-dark-raspberry);
}

.set_fav_loc_container {
    position: fixed;
    top: 100px;
    right: 0;
    padding: 5px;
    z-index: 10;
}
.set_fav_loc_container button svg {
    width: 40px;
    height: 40px;
    filter: drop-shadow(2px 4px 6px black);
}
.set_fav_loc_container button svg path {
    fill: var(--color-mint);
}

@media (max-width: 767px) {
    .supercategories {
        padding: 20px 0 0px;    
    }
}

@media (max-width: 600px) {
    .image_container {
        height: 110px;
        width: 110px;
        flex-basis: 150px;
        border-radius: 5px 0px 0px 5px;
    }
    .text_content {
        flex: 1;
    }
    .title {
        padding: 20px 15px 20px 0;
    }
}