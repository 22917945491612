.supercategories {
    padding: 60px 0 80px;    
}
.text_content {
    width: 100%;
    padding: clamp(20px, 1.8229vw, 35px) clamp(0px, 1.302vw, 25px) 20px;
}
.title {
    background: linear-gradient(-45deg,#333, #888,#fff);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    height: clamp(27px, 2.2916vw, 44px);
    width: 230px;
    max-width: 100%;
}
.title_icon {
    width: 40px;
    height: 40px;
}

.image_container {
    height: clamp(100px, 15.625vw, 300px);
    background-color: #fff;
    padding: 20px;
    margin-top: 14px;
    background: linear-gradient(-45deg,#333, #888,#fff);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

.button_link {
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
}

.button_link:hover .title {
    color: var(--color-dark-raspberry);
}
.title svg path {
    transition: all .3s;
}
.button_link:hover .title svg path {
    fill: var(--color-dark-raspberry);
}

.set_fav_loc_container {
    position: fixed;
    top: 100px;
    right: 0;
    padding: 5px;
    z-index: 10;
}
.set_fav_loc_container button svg {
    width: 40px;
    height: 40px;
    filter: drop-shadow(2px 4px 6px black);
}
.set_fav_loc_container button svg path {
    fill: var(--color-mint);
}

@media (max-width: 767px) {
    .supercategories {
        padding: 20px 0 0px;    
    }
}

@media (max-width: 600px) {
    .image_container {
        height: 80px;
        width: 110px;
        flex-basis: 110px;
    }
    .text_content {
        flex-basis: calc(100% - 130px);
    }
    .title {
        width: auto;
    }
    .button_link {
        flex-direction: row;
        align-items: center;
        gap: 20px;
        width: 100%;
        display: flex;
    }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}