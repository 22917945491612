.item {

}

.item_title {
    font-size: 20px;
    font-family: var(--font-bourton-hand-base);
}
.item_vendor {
    margin-top: 15px;
}
.item_vendor span {
    font-family: var(--font-brandon-text-bold);
}
.item_products {
    margin-top: 10px;
}

.controls {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
}
.controls button svg {
    width: 14px;
}
.controls button {
    align-items: flex-start;
    gap: 5px;
}


.product_item + .product_item {
    margin-top: 20px;
}
.product_name {
    color: var(--color-cocoa);
    font-family: var(--font-bourton-hand-base);
}
.choice span {
    font-family: var(--font-brandon-text-bold);
}