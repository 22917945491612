.checkout_tip {
    border-top: 1px solid var(--color-grey-stroke);
    border-bottom: 1px solid var(--color-grey-stroke);
    padding: 20px;
    margin-top: 20px;
}
.checkout_tip_header {
    display: flex;
    gap: 28px;
    align-items: center;
}
.checkout_tip_header_title {
    font-size: 24px;
    font-family: var(--font-bourton-hand-base);
    color: var(--color-cocoa);
    line-height: 1;

    display: flex;
    align-items: center;
    gap: 5px;
}
.checkout_tip_percent {
    display: table;
    /* border-collapse: collapse; */
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.checkout_tip_item {
    display: table-cell;  
    border: 1px solid var(--color-grey-stroke);
}
.checkout_tip_item button {    
    font-size: 14px;
    font-family: var(--font-bourton-hand-base);
    color: var(--color-text-blue);
    text-align: center;
    padding: 15px;
    background: transparent;
    width: 100%;
    border: 0;
}
button.active, button.selection_active {
    background-color: var(--color-dark-raspberry);
    color: #fff;
}
.checkout_tip_item button:disabled {
    opacity: .5;
    cursor: default;
}
.checkout_tip_item:first-child {
    border-radius: 5px 0px 0px 5px;
}
.checkout_tip_item:last-child {
    border-radius: 0px 5px 5px 0px;
}

.checkout_tip_amount {
    font-size: 18px;
    font-family: var(--font-brandon-text-bold);
    text-transform: uppercase;
    margin-top: 22px;

    display: flex;
    align-items: center;
    gap: 5px;
}

.other_tip {
    display: flex;
}
.other_tip div {
    margin: 0 !important;
}
.other_tip div button, .other_tip .loader {
    height: 100%;
}
.other_tip div:first-child {
    flex-basis: 60%;
}
.other_tip div:last-child {
    flex-basis: 40%;
}


.checkout_tip_amount button, .checkout_tip_amount .btn_placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: 0;
    background-color: transparent;
    transition: all .3s;
}

.checkout_tip_amount button svg path {
    fill: var(--color-dark-raspberry);
    transition: all .3s;
}

.checkout_tip_amount button:hover {
    background-color: var(--color-dark-raspberry);
}
.checkout_tip_amount button:hover svg path {
    fill: #fff;
}