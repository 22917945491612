.choice_basic {
    border: 2px solid var(--color-light-bg);
    border-radius: 5px;
    background-color: var(--color-grey-bg);
    padding: 2px;
}
.choice_basic_inner {
    background-color: #fff;
    padding: 15px clamp(15px, var(--clamp-size-24));
    height: 100%;
}
.choice_title {
    color: var(--color-cocoa);
    font-size: clamp(12px, var(--clamp-size-20));
    font-family: var(--font-bourton-hand-base);
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 2px;
}

.choice_picked {
    /* padding: 8px 25px 8px clamp(10px, var(--clamp-size-14)); */
    padding: 8px clamp(10px, var(--clamp-size-14));
    position: relative;
    border: 1px solid var(--color-grey-stroke);
    border-radius: 5px;
    line-height: 0.9;
    text-align: left;
    background: transparent;

    color: var(--color-dark-raspberry);
    font-family: var(--font-brandon-text-bold);
    text-decoration: underline;
    line-height: 1.1;
}

.choice_picked button {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    right: 5px;
    background: transparent;
    border: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 100%;
    transition: all .3s;
}
.choice_picked button svg {
    width: 100%;
    height: 100%;
}
.choice_picked button svg path {
    fill: var(--color-text-blue);
    transition: all .3s;
}
.choice_picked button:hover {
    background-color: var(--color-dark-raspberry);
}
.choice_picked button:hover svg path {
    fill: #fff;
}

.choice_picked_title {
    font-size: clamp(13px, var(--clamp-size-14));
}

.picked_sub_options {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

@media (max-width: 800px) {
    .choice_basic_inner {
        padding: 2px 16px;
    }
    .choice_picked {
        padding: 0;
        border: 0;
        color: var(--color-dark-raspberry);
        font-family: var(--font-brandon-text-bold);
        text-decoration: underline;
    }
    .choice_picked button {
        display: none;
    }
}
@media (max-width: 480px) {
    .choice_basic_inner {
        padding: 2px 4px;
    }
}


.choice_custom_fields {
    margin-top: 10px;
}
.field_value {
    padding: 8px clamp(10px, var(--clamp-size-14));
    position: relative;
    border: 1px solid var(--color-grey-stroke);
    border-radius: 5px;
    line-height: 0.9;
    text-align: left;
    background: transparent;
    line-height: 1.1;
}


/* // the new "improved" design  */
@media (max-width: 639px) {
    .choice_basic {
        background-color: transparent;
        padding: 12px 16px;
    }
    .choice_basic + .choice_basic {
        margin-top: 5px;
    }

    .choice_basic_inner {
        padding: 0;
        display: flex;
        align-items: center;
    }
    .choice_basic_inner > div {
        flex: 1;
    }
    .choice_title {
        margin-bottom: 0;
        line-height: 1;
    }
    .picked_sub_options {
        flex-direction: row;
        gap: 0;
        flex-wrap: wrap;
    }
    .choice_picked {
        margin: 0;
    }
    .choice_picked + .choice_picked::before {
        content: ", ";
        color: var(--color-text-blue);
    }
}