.days_container {
    display: flex;
    gap: 5px;
    justify-content: center;
    padding: 20px 0;
    flex-wrap: wrap;
}

.day {
    border: 1px solid var(--color-grey-stroke);
    padding: 5px;
    width: 55px;
    background-color: transparent;
    font-size: 16px;
    transition: all .3s;
}
.day.active, .day:hover {
    border-color: var(--color-dark-raspberry);
    background-color: var(--color-dark-raspberry);
    color: #fff;
}

.week_day {
    font-family: var(--font-brandon-text-bold);
}

/* .select {
    width: 300px;
    max-width: 100%;
    flex: 1;
} */
.select {
    /* padding-right: 65px;    */
    appearance: none;
    min-width: 230px;
    cursor: pointer;

    padding-right: 50px;   
    padding-left: 20px;
}
.select:disabled {
    cursor: default;
}
.selects_container {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin: 20px auto 0;
    flex-wrap: wrap;
}

.time_slots_container {
    position: relative;
    /* width: 300px; */
    max-width: 100%;
    flex: 1;
}

.loading_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.controls {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.open_drawer {
    position: absolute;
    /* top: 0;
    right: 0; */
    /* background: var(--color-dark-raspberry); */
    background: #fff;
    /* width: 50px;
    height: 55px; */
    top: 2px;
    right: 2px;
    width: 51px;
    height: 51px;
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.time_slots_container.disabled .open_drawer{
    opacity: .5;
}