.categories_header_container {    
    background-color: var(--color-pink);
    border-bottom: 5px solid var(--color-dark-raspberry);
    /* padding: clamp(13px, var(--clamp-size-25)) 0 clamp(5px, var(--clamp-size-15)); */
    padding: clamp(8px, var(--clamp-size-20)) 0 clamp(5px, var(--clamp-size-15));
    z-index: 50;

    /* overflow-x: auto; */
}
/* .categories_header_outer {
    overflow-y: auto;
} */
.categories_header_inner {
    display: flex;
    gap: 20px;
    justify-content: center;

    overflow-y: auto;
    padding-top: 5px;
}
.categories_header_inner_left {
    justify-content: flex-start;
}
.categories_header_inner::-webkit-scrollbar {
    display: none;
}
.categories_header_fixed .categories_header_inner {
    align-items: center;
    overflow-x: auto;
}
.item {
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
    /* max-width: 130px; */
    text-align: center;
    transition: all .35s;
    /* flex: 1; */

    min-width: 120px;
    max-width: 140px;
}
.image_container {
    width: clamp(70px, var(--clamp-size-90));
    height: clamp(70px, var(--clamp-size-90));
    background-color: #fff;
    border-radius: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    overflow: hidden;
    transition: all .3s;
}

.title {
    color: #584646;
    font-size: clamp(16px, var(--clamp-size-18));
    text-transform: uppercase;
    font-family: var(--font-bourton-hand-base);
    margin-top: 15px;
    transition: all .3s;
}
.categories_header_fixed .title {
    margin-top: 0;
}
.active .title, .item:hover .title {
    color: var(--color-dark-raspberry);
}
.active .image_container, .item:hover .image_container {
    box-shadow: 0px 0px 0px 4px var(--color-dark-raspberry);
}
.categories_header.sticky_menu {
    padding: 10px 0;
}
.categories_header.sticky_menu .image_container {
    display: none;
}
.categories_header.sticky_menu .item {
    max-width: unset;
}


.navigation_container {
    position: relative;
}
.controls {
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0%;
    display: flex;
    justify-content: space-between;
}
.controls button {
    border: 0;
    background: transparent;
    padding: 5px;
    pointer-events: all;
    position: relative;
}
.button_left {
    left: -30px;
}

.button_right {
    right: -30px;
}

@media (max-width: 768px) {
    .button_left {
        left: -20px;
    }    
    .button_right {
        right: -20px;
    }
}