.container_wrapper {
    width: 1320px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}
.container {
    position: relative;
    padding: 80px 0;
}

.title {
    color: var(--color-cocoa);
    text-transform: uppercase;
    line-height: 1.1;
    font-family: var(--font-bourton-hand-base);
    font-weight: normal;
    font-size: clamp(25px, var(--clamp-size-60));
    margin-bottom: 40px;
}

.subtitle {
    font-size: clamp(40px, var(--clamp-size-60));
    color: var(--color-dark-mint);
    font-family: var(--font-icing);
    line-height: 0.6;
}


.item {
    border-radius: 5px;
    background: #FFF;
    padding: 12px;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);
    border: 0;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
}

.state {
    font-family: var(--font-brandon-text-regular);
    font-size: clamp(20px, var(--clamp-size-24));
    color: var(--color-cocoa);
}

.store_number {
    font-family: var(--font-brandon-text-bold);
    font-size: clamp(20px, var(--clamp-size-24));
    color: var(--color-dark-raspberry);
}

.store_number_header {
    font-size: clamp(18px, var(--clamp-size-24));
}

.locations_counter {
    color: var(--color-cocoa);
}

.city_name {
    text-transform: capitalize;
}

/* breadcrumb  */
.breadcrumb {
    margin-bottom: clamp(24px, var(--clamp-size-40));
    display: flex;
    align-items: center;
    gap: 12px;
}
.separator {
    line-height: 0;
}
.separator svg {
    width: 20px;
    height: 20px;
}
.breadcrumb_link {
    font-size: clamp(16px, var(--clamp-size-18));
    font-family: var(--font-brandon-text-bold);
    color: var(--color-dark-raspberry);
    text-decoration: underline;
}

.breadcrumb_current {
    font-size: clamp(16px, var(--clamp-size-18));
    font-family: var(--font-brandon-text-bold);
    color: var(--color-cocoa);
    text-transform: capitalize;
}
