.sidebar_header {
    background-color: #fff;
    padding: 20px 30px 10px clamp(10px, var(--clamp-size-30));
    display: flex;
    gap: 20px;
    color: var(--color-cocoa);
    min-height: 78px;
}
.sidebar_header_message_type {
    font-family: var(--font-brandon-text-bold);
    text-transform: capitalize;
}
.sidebar_header_message_store_name {
    max-width: 212px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
}
.sidebar_header_message {
    display: flex;
    align-items: center;
    gap: 10px;
}

.sidebar_content {
    padding: 10px;
    flex: 1;
}
.sidebar_content_inner {
    border-radius: 21px;
    background: #FFF;
    padding: 24px 0;
    height: 100%;

    /* height: calc(100vh - 160px); */
    height: calc(100vh - 190px);
}
.sidebar_content_inner_empty {
    height: 100%;
}



.cart_timeline {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.cart_timeline_item {
    flex: 1;   
}
.cart_timeline_item button, .cart_timeline_item > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--font-brandon-text-bold);
    font-size: 16px;
    padding: 0;
    background: transparent;
    border: 0;
    margin: 0 auto;
}
.cart_timeline_item button:disabled {
    cursor: default;
}

.active button {
    color: var(--color-dark-raspberry);
}
.number {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    
    
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
}

.cart_timeline_item .number span {
    background-color: var(--color-grey-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-family: var(--font-brandon-text-bold);
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 1px solid var(--color-grey-stroke);
    transition: all .3s;
}

.cart_timeline_item .number svg, .cart_timeline_item .number .svg_loader {
    opacity: 0;
    pointer-events: none;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 7px;
    transition: all .3s;
}
.cart_timeline_item .number .svg_loader {
    display: flex;
    align-items: center;
    justify-content: center;
}
.active .number span {
    border: 2px solid var(--color-dark-raspberry);
}

.number:after {
    content: "";
    position: absolute;
    width: 165px;
    height: 4px;
    background-color: #E3E5E7;
    z-index: -1;
    left: 0;
    top: calc(50% - 2px);
}
@media (max-width: 440px) {
    .number:after {
        width: 135px;        
    }
}
.complete .number span {
    border: 2px solid var(--color-dark-raspberry);
    background-color: var(--color-dark-raspberry);
    font-size: 0;
}
.complete .number svg, .complete .number .svg_loader {
    opacity: 1;
}
.complete .number:after {
    background-color: var(--color-dark-raspberry);
}


.cart_timeline_item:last-child .number:after {
    display: none;
}

.cart_items {
    border-top: 1px solid #E3E5E7;
    padding-top: 13px;
    margin-top: 11px;
    padding-left: 15px;
    padding-right: 15px;

    height: calc(100% - 85px);
    overflow-y: auto;
}

.cart_items::-webkit-scrollbar {
    width: 5px;
}
.cart_items::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.cart_items::-webkit-scrollbar-thumb {
    background-color: var(--color-dark-raspberry);
    outline: 1px solid slategrey;
}

.no_items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.no_items h4 {
    margin-bottom: 20px;
}


/* info pannel  */
.info_pannel_header {
    background-color: #fff;
    padding: 10px 10px;
    display: flex;
    margin-bottom: 20px;
}
.info_pannel_header button {
    flex: 1;
    border: 1px solid var(--color-grey-stroke);
    background: transparent;
    color: var(--color-text-blue);
    font-size: 12px;
    text-transform: uppercase;
    font-family: var(--font-bourton-hand-base);
}
.info_pannel_header button:first-child {
    border-radius: 5px 0 0 5px;
}
.info_pannel_header button:last-child {
    border-radius: 0 5px 5px 0;
}

.info_pannel_header .btn_active {
    border-color: var(--color-dark-raspberry);
    color: var(--color-dark-raspberry);
}


/* guest form  */
/* .guest_form {
    margin-top: 20px;
} */


/* cart footer  */
.cart_footer {
    background-color: #fff;
    padding: 20px 30px;

    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    filter: drop-shadow(2px 4px 6px black);
}
.cart_footer_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 47px;
}
.cart_footer button {
    width: 100%;

    font-size: clamp(16px,var(--clamp-size-18));
    font-family: var(--font-brandon-text-bold);
}


/* checkout pannel  */
.checkout_location_date_info {
    border-radius: 5px;
    background-color: var(--color-grey-bg);
    padding: 15px;
    display: flex;
    gap: 20px;
}

.checkout_location_date_info + .checkout_location_date_info {
    border-top: 1px solid var(--color-grey-stroke);
}
.checkout_location_date_info > div {
    flex: 1;
}
.checkout_location_date_info_title {
    font-size: clamp(16px, var(--clamp-size-18));
    font-family: var(--font-brandon-text-bold);
    text-transform: uppercase;

    display: flex;
    align-items: center;
    gap: 10px;
}
.checkout_location_date_info_title svg path {
    fill: var(--color-dark-raspberry);
}
.checkout_location_date_info_more {
    margin-top: 14px;
    font-size: 16px;
    color: var(--color-cocoa);
    text-transform: capitalize;
}
.checkout_location_date_info_cta {
    margin-top: 20px;
}
.checkout_location_date_info_cta button {
    font-size: clamp(16px, var(--clamp-size-18));
    font-family: var(--font-brandon-text-bold);
    text-decoration: underline;
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    color: var(--color-dark-raspberry);
}



.billing_loader {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* total costs  */
.order_total_container {
    background-color: var(--color-grey-bg);
    padding: 20px;
    margin-top: 25px;
    border-radius: 5px;
}
.order_total_header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    font-family: var(--font-bourton-hand-base);
    font-size: 20px;
    color: var(--color-cocoa);
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--color-grey-stroke);
}
.order_total_header h3 {
    font-family: var(--font-bourton-hand-base);
    font-size: 20px;
    color: var(--color-cocoa);
    font-weight: normal;
}

.order_total_item {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.order_total_item + .order_total_item {
    margin-top: 10px;
}

.user_info {
    margin-top: 20px;
}
.user_info span {
    font-family: var(--font-brandon-text-bold);
    margin-right: 5px;
}


.order_complete {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.disclaimer {
    margin-top: 14px;
    font-size: 14px;
}



.modal_controls {
    display: flex;
    gap: 5px;
    justify-content: center;

    width: 300px;
    margin: 0 auto;

}
.modal_controls button, .modal_controls .btn {
    width: auto;
    flex: 1;
    gap: 5px;
}
.modal_footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 639px) {
    .mobile_flex_wrap {
        flex-direction: column;
    }
}

.max_width_container {
    max-width: 120px;
}

.migrate_disclaimer {
    border: 2px solid var(--color-dark-raspberry);
    color: var(--color-dark-raspberry);
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 30px;
    background-color: var(--color-light-bg);
    font-size: 12px;
}
.migrate_disclaimer button {
    text-decoration: underline;
    appearance: none;
    border: 0;
    margin: 0;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}
.migrate_disclaimer p + p {
    margin-top: 16px;
}