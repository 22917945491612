.order_delivery_stages {
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    margin: 0 auto 40px;
    overflow: hidden;
}
.order_delivery_stage {
    text-align: center;
    font-family: var(--font-brandon-text-bold);
    font-size: clamp(14px, var(--clamp-size-20));
    flex: 1;
}
.order_delivery_stage span {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 1px solid var(--color-grey-stroke-dark);
    margin: 0 auto 15px;
    font-family: var(--font-brandon-text-bold);
    font-size: 18px;
    position: relative;
    background-color: #fff;
}
.order_delivery_stage span:after {
    content: "";
    height: 4px;
    background: var(--color-grey-stroke-dark);
    /* 1800  */
    width: clamp(96px, 20vw, 360px);
    position: absolute;
    right: 0;
    z-index: -1;
}
.order_delivery_stage:first-child span:after {
    display: none;
}
.stage_active {
    color: var(--color-dark-raspberry);
}
.stage_active span {
    background-color: var(--color-dark-raspberry);
    border-color: var(--color-dark-raspberry);
}
.stage_active span:after {
    background:  var(--color-dark-raspberry);
}

@media (max-width: 1024px) {
    .order_delivery_stage span:after {
        width: clamp(96px, 22.4609vw, 230px);
    }
}