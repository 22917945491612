.container {
    width: 1100px;
    max-width: 100%;
    padding: 0 20px;
    margin: 40px auto;
    
}
.container_innner {
    text-align: center;
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid var(--color-dark-raspberry);
    padding: 70px 20px;
}
.modal_controller {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    justify-content: center;
}

.warning_header {
    font-family: var(--font-bourton-hand-base);
    color: var(--color-cocoa);
}
.warning_header svg {
    margin-bottom: 10px;
    margin-right: 5px;
}
.warning_header svg path {
    fill: var(--color-dark-raspberry);
}
.warning_header strong {
    color: var(--color-dark-raspberry);
}