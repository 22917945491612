.item {
    background-color: #fff;
    filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.15));
    /* padding: 25px 15px; */
    display: flex;
    border-radius: 5px;
    transition: all .3s;    
    height: 100%;
    padding: 10px 0;
}

.item_no_padding {
    padding: 0;
}
.item:hover {
    filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.45));
}
.item a {
    display: flex;
}
.item.column, .item.column a {
    flex-direction: column;
}
.item.column_perma {
    flex-direction: column;
}

@media (max-width: 600px) {
    .item.column, .item.column a, .item.column button {
        flex-direction: row;
        align-items: center;
        gap: 12px;
        width: 100%;
        display: flex;
    }
}