.option_container {
    position: relative;
}
.option {
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.option:disabled {
    cursor: default;
}
.option:not(.active):disabled {
    opacity: .7;
}

.image_container {
    width: clamp(110px, var(--clamp-size-150));
    height: clamp(110px, var(--clamp-size-150));
    border: 1px solid var(--color-grey-stroke);
    border-radius: 100%;
    /* background-color: var(--color-grey-bg); */
    background-color: #fff;
    position: relative;
    margin: 0 auto;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}
.image_container_inner {
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.image_container img {
    max-width: 85%;
}
.name {
    font-size: clamp(14px, var(--clamp-size-18));
    margin-top: 15px;
}

.option_description {
    font-size: 14px;
    font-family: var(--font-brandon-text-bold);
    color: #584446;
}
.option_price {
    font-family: var(--font-brandon-text-bold);
    color: #584446;
}

.increment_decrement_container {
    display: flex;
    width: 100%;
    margin-top: 12px;
    justify-content: center;
    align-items: center;
    gap: 16px;
}
.increment_decrement_container button {
    border: 2px solid var(--color-dark-raspberry);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
}

.increment_decrement_container button svg {
    width: 70%;
}
.increment_decrement_container button path {
    fill: var(--color-dark-raspberry)
}
.item_count {
    font-size: 22px;
    color: var(--color-cocoa);
}

.check_mark {
    display: none;
    position: absolute;
    top: clamp(2px, 0.4167vw, 8px);
    right: clamp(2px, 0.4167vw, 8px);
    background-color: var(--color-dark-raspberry);
    width: 24px;
    height: 24px;
    border-radius: 100%;
    
    align-items: center;
    justify-content: center;
}
.check_mark svg {
    width: 60%;
}
.check_mark path {
    fill: #fff;
}

.active, button.active:disabled  {
    font-family: var(--font-brandon-text-bold);
    color: var(--color-text-blue);
}


.active .image_container {
    box-shadow: 0px 0px 0px 3px var(--color-dark-raspberry);
}


.active .check_mark {
    display: flex;
}

.option_title, .option_title button {
    font-size: 18px;
    line-height: 1.5;
    margin-top: 15px;
    text-align: center;
    width: 100%;
}
.option_price {
    color: var(--color-cocoa);
    font-family: var(--font-brandon-text-bold);
}

/* ///////// choice with no image */

.option_simple {
    flex: 1;
    width: 100%;
}
.option_simple {
    border-radius: 5px;
    border: 1px solid var(--color-grey-stroke);
    background: var(--color-grey-bg);
    display: block;
    padding: clamp(5px, var(--clamp-size-10));
    cursor: pointer;
    position: relative;
    height: 100%;
    cursor: pointer;
    flex-direction: row;
}
.option_simple .check_mark {
    display: none;
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: var(--color-dark-raspberry);
    width: 24px;
    height: 24px;
    border-radius: 100%;
    
    align-items: center;
    justify-content: center;
}
.option_simple .check_mark svg {
    width: 60%;
}
.option_simple .check_mark path {
    fill: #fff;
}
.option_simple.active {
    /* border: 3px solid var(--color-dark-raspberry); */
    box-shadow: 0px 0px 0px 3px var(--color-dark-raspberry);
    cursor: default;
}
.option_simple.active .check_mark {
    display: flex;
}
.option_simple .image_container {
    display: none;
}
.option_simple .option_title {
    background-color: #fff;
    padding: 12px clamp(12px, var(--clamp-size-20));
    /* font-size: clamp(14px,var(--clamp-size-16)); */
    font-size: 18px;
    font-family: var(--font-bourton-hand-base);
    height: 100%;
    line-height: 1.1;
    margin: 0;
    text-align: left;
}

.option_simple .option_price {
    color: var(--color-cocoa);
    font-size: 16px;
    font-family: var(--font-brandon-text-bold);
    display: block;
    margin-top: 10px;
}

.option_aggregate {
    position: relative;
}

.option_aggregate .option {
    width: 100%;
}


/* tooltip  */
.option_title_controller button {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    display: inline;
}
.tooltip_container {
    /* position: relative;
    display: inline; */
    position: absolute;
    display: inline;
    top: 0;
}
.tooltip_button {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
}
.tooltip_modal {
    border: 1px solid var(--color-dark-raspberry);
    /* padding: 8px 4px; */
    background-color: #fff;
    border-radius: 4px;
    text-align: center;
    font-size: 13px;
    color: var(--color-cocoa);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 190px;
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.16));
    margin-bottom: 7px;
}
.tooltip_modal_inner {
    padding: 8px 4px;
    background-color: #fff;
    border-radius: 4px;
    z-index: 2;
    position: relative;
}

.tail_icon {
    position: absolute;
    top: 99%;
    left: 50%;
    transform: translate(-50%, 0);
    height: 9px;
    line-height: 0;
    z-index: 1;
    /* position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -5.6px); */
}