.form_input_container {
    display: flex;
    position: relative;
    position: relative;
}
.form_input_container input {
    border-radius: 5px 0 0 5px !important;
    /* border-right: 0;
    border-right: 0 !important; */
}
.form_input_container button {
    background-color: var(--color-dark-raspberry);
    border: 0;
    width: 55px;
    height: 55px;
    border-radius: 0 5px 5px 0;
}


.no_location_found {
    text-align: center;
    padding: 24px 20px;
    background-color: var(--color-pink);
}

.no_location_found_text {
    margin-top: 25px;
    font-family: var(--font-brandon-text-bold);
    font-size: clamp(20px, var(--clamp-size-20));
    margin: 25px auto;
}

.location_container {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid var(--color-border);
    /* overflow-y: auto;
    height: 40vh; */
    padding-bottom: 60px;
}
.location_container_counter {
    font-size: 18px;
    font-family: var(--font-brandon-text-bold);
    margin-bottom: 12px;
}
.location_container::-webkit-scrollbar {
    width: 5px;
}
.location_container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.location_container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}



.current_location {
    margin-bottom: 30px;
}

.info {
    margin-bottom: 20px;
}
.info span {
    font-family: var(--font-brandon-text-bold);
}


.curbisde_pannel {
    margin-top: 20px;
    margin-bottom: 20px;
}
.curbside {
    margin-top: 20px;
}

.current_location_address {
    /* margin-top: 20px; */
    margin-bottom: 8px;
}
.current_location_address span {
    font-family: var(--font-brandon-text-bold);
}



.results {
    border: 1px solid var(--color-border);;
    border-radius: 5px;
    margin-top: 10px;
}

.result_item {
    font-size: 16px;
    color: (var(--color-text-blue));
    font-family: var(--font-brandon-text-regular);
    padding: 15px 30px;
    text-align: center;
}
.result_item:hover {
    background-color: #ECC5DE33;
    font-family: var(--font-brandon-text-bold);
}

.helper {
    padding: 16px;
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
}
.helper p {
    max-width: 360px;
    margin: 0 auto;
}

.link_container {
    margin-top: 24px;
    
}
.link_container button {
    font-size: 18px;
    font-family: var(--font-brandon-text-bold);
    color: var(--color-dark-raspberry-secondary);
}

.helper_footer {
    border-top: 1px solid var(--color-border);
    margin-top: 24px;
    padding-top: 16px;
}

.helper_footer p {
    max-width: 300px;
}


/* AutocompleteComponentExtra  */

.loader_container {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
}
.btn_clear {
    background: transparent !important;
    width: 48px;
    border: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}