

.categories_controller_item {
    /* flex: 1; */
    text-align: center;
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    /* max-width: 120px; */
    max-width: 150px;
    min-width: 100px;
    transition: all .3s;
    font-size: clamp(14px, var(--clamp-size-18));
}


.icon {
    width: clamp(50px, var(--clamp-size-70));
    height: clamp(50px, var(--clamp-size-70));
    border: 1px solid #E3E5E7;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-color: #fff;
    padding: 5px;
    transition: all .2s;
}
.icon svg, .icon img {
    width: 60%;
    max-height: 60%;
    transition: all .3s;
}
.categories_controller_item.active .icon, .categories_controller_item:hover .icon {
    /* background-color: var(--color-dark-raspberry); */
    /* border-color: #8D0E57;
    border-width: 3px; */
    box-shadow: 0px 0px 0px 3px var(--color-dark-raspberry);
}
/* .categories_controller_item:hover .icon img {
    max-width: 70%;
    max-height: 70%;
} */
/* .categories_controller_item.active .icon svg path {
    fill: #fff;
} */

.name {
    margin-top: 5px;
    line-height: 1.1;
    transition: all .3s;
    margin-top: clamp(10px, var(--clamp-size-16));
}

.categories_controller_item.active .name, .categories_controller_item:hover .name {
    font-family: var(--font-brandon-text-bold);
    color: #8D0E57;
}