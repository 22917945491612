.billing_header {
    display: flex;
    gap: 11px;
    margin-top: 20px;
    position: relative;
}

.billing_header > div {
    flex: 1;
}

.btn_hand {
    font-family: var(--font-bourton-hand-base);
    font-size: 18px;
    padding: 14px 11px 9px;
    width: 100%;
    height: 41px;
    white-space: nowrap;
}
.active {
    background-color: var(--color-dark-raspberry);
    color: #fff;
}

.absolute_loader {
    position: absolute;
    max-width: 48%;
    right: 0;
}