.title {
    /* padding: 35px 25px 20px; */
    color: #584646;
    font-size: clamp(16px, var(--clamp-size-22));
    text-transform: uppercase;
    font-family: var(--font-bourton-hand-base);
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.1;
}
.description {

}
.text_content {
    /* flex: 1; */
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: clamp(10px, var(--clamp-size-20));
    height: 100%;
}
.image_container {
    /* min-height: clamp(140px, var(--clamp-size-300)); */
    background-color: #FBFAF8;
    /* flex: 1; */
    flex-basis: 50%;
}
.btn {
    cursor: pointer !important;
}
.cta_container {
    margin-top: clamp(10px, var(--clamp-size-30));
}