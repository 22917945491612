.sidebar_header {
    background-color: #fff;
    padding: 20px 50px 10px 20px;
    display: flex;
    /* gap: 1px; */

    min-height: 78px;
}
.sidebar_header > * {
    flex: 1;
}
.sidebar_header button {
    font-family: var(--font-bourton-hand-base);
    font-size: clamp(14px, var(--clamp-size-18));
    padding: 13px 20px;
    display: flex;
    gap: 5px;
    align-items: center;
    line-height: 1;
    border-radius: 0;
}
.sidebar_header button span {
    padding-top: 4px;
}
.sidebar_header button:first-child {
    border-radius: 5px 0 0 5px;
}
.sidebar_header button:last-child {
    border-radius: 0 5px 5px 0;
}
.sidebar_content {
    padding: 10px;
    flex: 1;
    
}

.sidebar_content_wrapper {
    background-color: #fff;
    border-radius: 21px;
    padding-top: 80px;
}
.controls {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    padding: 0 15px;
}

.controls button {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 18px;
    border-radius: 0;
    flex: 1;
}
.controls .btn_round_left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}  
.controls .btn_round_right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}  
.sidebar_content_inner {
    border-radius: 21px;
    background: #FFF;
    padding: 24px 15px;
    /* height: 100%; */


    overflow: auto;
    height: calc(100vh - 100px);
}

.sidebar_content_inner::-webkit-scrollbar {
    width: 5px;
}
.sidebar_content_inner::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.sidebar_content_inner::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

