.banner {
    /* background-position: bottom center;
    background-repeat: no-repeat;
    background-image: url(../../assets/Wavy_Desktop_v2.svg);
    background-repeat: repeat-x;
    padding-bottom: 59px; */
}


.banner_background {
    /* background-color: #c7ebf1; */
    
}


.banner_slide {    
    background-position: bottom center;
    background-repeat: no-repeat;
    /* background-image: url(../../assets/Wavy_Desktop_v2.svg); */
    background-repeat: repeat-x;
    padding-bottom: 59px;
}
.banner_slide_inner {
    background-color: #f7f6f4;
    padding-bottom: 60px;
}
/* .banner_slide:nth-child(2) {
    background-image: url(../../assets/Wavy_Desktop_v2_s2.svg);
} */
/* .banner_slide:nth-child(2) .banner_slide_inner {
    background-color: #a8d5d6;
} */
/* .banner_slide:nth-child(3) {
    background-image: url(../../assets/Wavy_Desktop_v2_s3.svg);
} */
/* .banner_slide:nth-child(3) .banner_slide_inner {
    background-color: #f9de8d;
} */

.banner_inner {
    text-align: left;
    display: flex;
    min-height: clamp(500px, 39.0625vw, 750px);
    align-items: center;
    min-height: 270px;
}
.banner_inner > div {
    flex: 1;
}

.banner_text {
    display: flex;
    align-items: center;
}
.banner_text_inner {
    text-align: center;
    max-width: 720px;
    margin: 0 auto;
    
}

.banner_text_footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.subtitle {
    font-size: clamp(40px, var(--clamp-size-70));
    color: var(--color-dark-mint);
    font-family: var(--font-icing);
    line-height: 0.6;
}
.banner_slide:nth-child(2) .subtitle {
    color: var(--color-cocoa);
}

.title {
    font-size: clamp(36px, var(--clamp-size-70));
    color: var(--color-dark-raspberry);
    text-transform: uppercase;
    line-height: 1.1;
    font-family: var(--font-bourton-hand-base);
    font-weight: normal;
}

.description {
    color: var(--color-cocoa);
    font-size: clamp(20px, var(--clamp-size-30));
}

.banner_image {
    height: 100%;
}

.custom_splide_pagination {
    background-color: var(--color-dark-raspberry);
    padding: clamp(10px, 0.7812vw, 15px) clamp(17px, 1.1458vw, 22px);
    border-radius: 40px;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    position: absolute;
    bottom: 58px;
}

@media (max-width: 768px) {
    .banner {
        padding-bottom: 44px;
    }
    .banner_inner {
        flex-direction: column;
        padding: 40px 0 0;
        height: auto;
    }
    .banner_image {
        width: 90%;
    }
}
@media (max-width: 767px) {
    .banner_image {
        margin-top: 10px;
    }
}