.wrapper {
    /* border-radius: 5px;
    background-color: #FFF;
    filter: drop-shadow(2px 4px 10px rgba(0, 0, 0, 0.15));
    padding: 60px 125px; */

    padding: clamp(30px, var(--clamp-size-60)) clamp(20px, var(--clamp-size-125));
    background-color: #fff;
    border: 1px solid var(--color-grey-stroke);
    border-radius: 5px;
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.16));
    margin: 40px 0;
}
@media (max-width: 1024px) {
    .wrapper {
        padding: 30px 20px;
    }
}

.page_header {
    font-family: var(--font-bourton-hand-base);
    font-weight: normal;
    text-align: center;
    font-size: clamp(40px, var(--clamp-size-80));
}

.pickup_time {
    border: 1px solid var(--color-grey-stroke);
    padding: 10px clamp(10px, var(--clamp-size-60));
    border-radius: 5px;
    margin-top: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.pickup_time_label {
    display: flex;
    align-items: center;
    gap: 10px;
}
.pickup_time span {
    font-family: var(--font-brandon-text-bold);
}
@media (max-width: 767px) {
    .pickup_time {
        flex-direction: column;
    }
    /* .pickup_time_label {
        display: flex;
        align-items: center;
        gap: 10px;
    } */
}
.directions {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}


.thank_you {
    padding: clamp(20px, var(--clamp-size-50));
    border: 1px solid var(--color-grey-stroke);
    margin-top: clamp(10px, var(--clamp-size-30));
    border-radius: 5px;
}
.page_text {
    color: #242C37;
    text-align: center;
    font-size: clamp(20px, var(--clamp-size-24));
    font-family: var(--font-brandon-text-bold);
}

.details {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

@media (max-width: 1023px) {
    .details {
        flex-direction: column;
    }
}


.details_item {
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid var(--color-grey-stroke);
}
.details_delivery {
    flex: 2;
}
.details_summary {
    flex: 3;
}
.details_header {
    padding: 17px 30px;
    border-bottom: 1px solid var(--color-grey-stroke);
    font-family: var(--font-bourton-hand-base);
    color: var(--color-cocoa);
    font-size: clamp(20px, var(--clamp-size-30));
    display: flex;
    align-items: center;
    gap: 10px;
}

.aditional_information {
    margin-top: 30px;
}

.card_container {
    padding: clamp(10px, var(--clamp-size-20));
}

.card {
    background-color: var(--color-grey-bg);
    border-radius: 5px;
    padding: 15px clamp(15px, var(--clamp-size-30));
    margin-top: 20px;
}
.card_item {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    gap: 5px;
}
.card_item_border {
    border-top: 1px solid var(--color-grey-stroke);
    padding-top: 10px;
}
.card_item + .card_item {
    margin-top: 10px;
}
.card_item_simple + .card_item_border {
    margin-top: 10px;
}
.card_item  span {
    font-family: var(--font-brandon-text-bold);
    font-size: 18px;
}

.card_item_header {
    color: 20px;
    font-family: var(--font-bourton-hand-base);
    color: var(--color-cocoa);
}
.location_address, .location_name {
    font-family: var(--font-brandon-text-bold);
}
.location_distance {
    font-size: 14px;
    margin-top: 5px;
    color: var(--color-cocoa);
}
.location_phone {
    margin-top: 16px;
    border-top: 1px solid var(--color-grey-stroke);
    padding: 10px 0;
    border-bottom: 1px solid var(--color-grey-stroke);
    font-size: 16px;
}
.store_info_header {
    font-family: var(--font-brandon-text-bold);
    text-transform: uppercase;

    display: flex;
    align-items: center;
    gap: 10px;
}

.card + .store_info_header {
    margin-top: 20px;
}


.item_header {
    display: flex;
    justify-content: space-between;
}
.item_price {
    font-family: var(--font-brandon-text-bold);
}
.item_title {
    color: var(--color-cocoa);
    font-family: var(--font-brandon-text-bold);
}
.item_description {
    font-size: 14px;
    margin-top: 10px;
}

.order_page_footer {
    display: flex;
    justify-content: center;
    gap: 20px;
}
.order_page_footer button {
    min-width: 100px;
}

.order_status {
    font-size: clamp(18px, var(--clamp-size-20));
    font-family: var(--font-bourton-hand-base);
    margin-bottom: 20px;
}